import React, { useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import store from "../../core/store";

import { useSelector } from "react-redux";
import Searchpage from "../pages/Search/Search.page";
import { loginWithMicrosoft, setUser } from "../../core/actions/auth.action";
import { setAuthToken } from "../../core/helper/setAuthToken";
import { ENVIRONMENT, PUBLIC_ROUTES, USER_TOKEN_KEY } from "../../config/constant";
import UserCenteredDashboard from "../pages/Dashboard/UserCenteredDashboard.page";
import CategoryCenteredDashboard from "../pages/Dashboard/CategoryCenteredDashboard.page";

const bootstrap = () => {
  const authToken = window.localStorage.getItem(USER_TOKEN_KEY);
  if (authToken) {
    const decoded = jwtDecode(authToken);
    store.dispatch(setUser(decoded));
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      localStorage.removeItem(USER_TOKEN_KEY);
      window.location.href = "/";
    } else {
      setAuthToken(authToken);
      store.dispatch(loginWithMicrosoft(authToken, null));
    }
  }
};

process.env.REACT_APP_ENVIRONMENT !== "DEVELOPMENT" && bootstrap();

const ProtectedRoutes = () => {
  const authStore = useSelector((s) => s.authStore);
  const navigate = useNavigate();
  // const location = useLocation();

  useEffect(() => {
    // if ([PUBLIC_ROUTES.includes(location.pathname)]) {
    //   navigate(location.pathname)
    //   return
    // }

    if (
      !authStore.isAuthenticated &&
      process.env.REACT_APP_ENVIRONMENT !== ENVIRONMENT.DEVELOPMENT
    ) {
      navigate("/");
    }
  }, [authStore.isAuthenticated]);

  return (
    <>
      <Routes>

        {/* USER CENTERED DASHBOARD */}
        <Route path="/user-centered-dashboard/:userId" element={<UserCenteredDashboard />}></Route>
        <Route path="/category-centered-dashboard/:categoryId" element={<CategoryCenteredDashboard />}></Route>

        {/* HOMEPAGE */}
        <Route path="/search" element={<Searchpage />}></Route>
      </Routes>
    </>
  );
};

export default ProtectedRoutes;
