const PdpVisitedSkeleton = () => {
    return (<div className="w-full">
        <div className="flex items-center justify-between">
            <h1 className="h-[15px] w-[40%] skeleton-container">
                <span className="skeleton-content"></span>
            </h1>
            <span className="skeleton-container w-[110px] h-4">
                <span className="skeleton-content"></span>
            </span>
        </div>
        <div className="border border-[#DEDEDE] mt-4 bg-white rounded-xl rounded-bl-none rounded-br-none">
            <div className="flex items-center px-4 py-3">
                <div className="w-3/5 text-sm font-medium text-[#3A3A3A] pr-4">
                    <p className="skeleton-container h-4 bg-[#f2f2f2] w-[75%]">
                        <span className="skeleton-content"></span>
                    </p>
                </div>
                <div className="w-1/5 text-sm font-medium text-[#3A3A3A] px-4">
                    <p className="skeleton-container h-4 bg-[#f2f2f2] w-[75%]">
                        <span className="skeleton-content"></span>
                    </p>
                </div>
                <div className="w-1/5 text-sm font-medium text-[#3A3A3A]">
                    <p className="skeleton-container h-4 bg-[#f2f2f2] w-[75%]">
                        <span className="skeleton-content"></span>
                    </p>
                </div>
            </div>
        </div>
        <div className="border border-[#DEDEDE] bg-white rounded-xl rounded-tl-none rounded-tr-none max-h-[436px] overflow-auto">
            <div className="flex p-4 border-t border-[#DEDEDE] first:border-t-0">
                <div className="w-3/5 flex pr-4">
                    <div className="w-[54px] h-[54px] min-w-[54px] skeleton-container">
                        <span className="skeleton-content"></span>
                    </div>
                    <div className="pl-4 w-full">
                        <p className="w-[80px] h-3 skeleton-container">
                            <span className="skeleton-content"></span>
                        </p>
                        <p className="w-[80%] h-3 skeleton-container mt-1">
                            <span className="skeleton-content"></span>
                        </p>
                        <p className="w-[40%] h-3 skeleton-container mt-0.5">
                            <span className="skeleton-content"></span>
                        </p>
                    </div>
                </div>
                <div className="w-1/5 text-sm text[#3A3A3A] px-4">
                    <p className="skeleton-container h-3 bg-[#f2f2f2] w-[60%]">
                        <span className="skeleton-content"></span>
                    </p>
                </div>
                <div className="w-1/5 text-sm text[#3A3A3A]">
                    <p className="skeleton-container h-3 bg-[#f2f2f2] w-[60%]">
                        <span className="skeleton-content"></span>
                    </p>
                </div>
            </div>
        </div>
    </div>)
}

export default PdpVisitedSkeleton