import React from "react";
import queryString from "query-string";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DEFAULT_COUNT, DEFAULT_DEVICE, DEFAULT_TIME_FRAME, DEFAULT_TIME_FRAME_OPTIONS, DEFAULT_ACTIVE_USER_COUNT, DEFAULT_TOP_USERS_COUNT, DEFAULT_TOP_USER_DAY_COUNT, DEFAULT_TOP_USER_LAST_N_DAY_COUNT, SORT_BY, TOP_ACTIVE_USER_TABLE_HEADERS } from "../../../config/constant";
import { getTopActiveUsers } from "../../../core/actions/analytic.action";
import { formatRevenue, platformMapper } from "../../../utils/helperFunctions";
import { useNavigate } from "react-router-dom";
import TopActiveUserSkeleton from "../Skeleton/TopActiveUserSkeleton";
import NoresultFound from "../NoresultFound";

const TopActiveUser = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { topActiveUsers, topActiveUserApiLoader } = useSelector(s => s.analyticStore)

    const [searchCriteria, setSearchCriteria] = useState({
        lastNDays: DEFAULT_TOP_USER_LAST_N_DAY_COUNT,
        device: DEFAULT_DEVICE,
        count: DEFAULT_TOP_USERS_COUNT
    })
    const [sortBy, setSortBy] = useState(SORT_BY.ASC)

    useEffect(() => {
        const stringifiedSearchCriteria = queryString.stringify(searchCriteria)
        dispatch(getTopActiveUsers(stringifiedSearchCriteria, null))
    }, [searchCriteria])

    const handleSorting = (fieldName) => {
        let _sortBy = sortBy === SORT_BY.ASC ? SORT_BY.DESC : SORT_BY.ASC
        setSortBy(_sortBy)
        let clonedSearchCriteria = { ...searchCriteria }
        clonedSearchCriteria.order = _sortBy
        clonedSearchCriteria.sortingField = fieldName
        const _stringifiedSearchCriteria = queryString.stringify(clonedSearchCriteria)
        dispatch(getTopActiveUsers(_stringifiedSearchCriteria, null))
    }

    return (
        <>
            <div className="w-full">
                <div className="flex items-center justify-between">
                    <div className="flex">
                        <h1 className="font-bold text-lg leading-6 mr-3 sm:text-base sm:mr-2.5">Top Active User</h1>
                        <select onChange={(e) => setSearchCriteria({ ...searchCriteria, count: e.target.value })} className="w-[120px] py-1.5 px-2.5 border border-[#BFBFBF] rounded-md text-xs bg-white text-[#0B66F8] font-semibold cursor-pointer focus:outline-none focus:ring-0 sm:hidden">
                            {DEFAULT_ACTIVE_USER_COUNT?.map((_d, index) =>
                                <option selected={_d === DEFAULT_TOP_USERS_COUNT} value={_d} key={index}>Top {_d} Users</option>
                            )}
                        </select>
                        <select onChange={(e) => setSearchCriteria({ ...searchCriteria, count: e.target.value })} className="w-[60px] h-[30px] py-1.5 px-2.5 border border-[#BFBFBF] rounded-md text-xs bg-white text-[#0B66F8] font-semibold cursor-pointer focus:outline-none focus:ring-0 md:hidden ">
                            {DEFAULT_ACTIVE_USER_COUNT?.map((_d, index) =>
                                <option selected={_d === DEFAULT_TOP_USERS_COUNT} value={_d} key={index}>{_d}</option>
                            )}
                        </select>
                    </div>
                    <div>
                        <select onChange={(e) => setSearchCriteria({ ...searchCriteria, lastNDays: e.target.value })} className="w-[110px] py-1.5 px-2.5 border border-[#BFBFBF] rounded-md text-xs bg-white text-[#0B66F8] font-semibold cursor-pointer focus:outline-none focus:ring-0">
                            {DEFAULT_TIME_FRAME_OPTIONS?.map((_d, index) =>
                                <option selected={_d === DEFAULT_TOP_USER_LAST_N_DAY_COUNT} value={_d} key={index}>Last {_d} days</option>
                            )}
                        </select>
                    </div>
                </div>
                <div className="md:visible border border-[#DEDEDE] rounded-xl mt-4 max-h-[436px] sm:overflow-scroll scrollbar-hide overflow-auto">
                    {/* <div className="border border-[#DEDEDE] mt-4 bg-white rounded-xl rounded-bl-none rounded-br-none"> */}
                        <div className="flex w-full sm:sticky sm:top-0 sm:w-fit sm:z-[1] sticky top-0 bg-white border-b border-b-[#DEDEDE] rounded-tl-xl rounded-tr-xl">
                            <div className="w-1/5 text-sm font-semibold text-[#3A3A3A] px-4 py-3 sm:sticky sm:top-0 sm:w-[100px] sm:min-w-[100px] sm:bg-[#f2f2f2] sm:[&:nth-child(1)]:sticky sm:[&:nth-child(1)]:left-0 sm:[&:nth-child(1)]:bg-[#f2f2f2] sm:[&:nth-child(1)]:z-[1]">User ID</div>
                            <div onClick={() => handleSorting("totalSession")} className="w-[15%] text-sm font-semibold text-[#3A3A3A] px-4 py-3 flex cursor-pointer sm:sticky sm:top-0 sm:w-[100px] sm:min-w-[100px] sm:bg-[#f2f2f2]">Total Session 
                             </div>
                             {/* <ContractUpDownFillIcon size={16} className="mt-[2px] ml-1" /> */}
                            <div className="w-[18%] text-sm font-semibold text-[#3A3A3A] px-4 py-3 sm:sticky sm:top-0 sm:w-[100px] sm:min-w-[100px] sm:bg-[#f2f2f2]">Avg Visit Duration</div>
                            <div onClick={() => handleSorting("orderCount")} className="w-[18%] text-sm font-semibold text-[#3A3A3A] px-4 py-3 cursor-pointer sm:sticky sm:top-0 sm:w-[100px] sm:min-w-[100px] sm:bg-[#f2f2f2]">Total Order Count</div>
                            <div onClick={() => handleSorting("revenueGenerated")} className="w-[15%] text-sm font-semibold text-[#3A3A3A] px-4 py-3 cursor-pointer sm:sticky sm:top-0 sm:w-[100px] sm:min-w-[100px] sm:bg-[#f2f2f2]">Revenue Generated (&#8377;)</div>
                            <div className="w-[14%] text-sm font-semibold text-[#3A3A3A] px-4 py-3 sm:sticky sm:top-0 sm:w-[100px] sm:min-w-[100px] sm:bg-[#f2f2f2]">Referrer</div>
                        </div>
                    {/* </div> */}
                    {!topActiveUserApiLoader ?
                        // <div className="border border-[#DEDEDE] bg-white rounded-xl rounded-tl-none rounded-tr-none max-h-[436px] overflow-auto scrollbar-hide">
                            topActiveUsers?.length > 0 ? (

                                topActiveUsers.map((_d, index) =>
                                    <div key={index} className="flex w-full border-t border-[#DEDEDE] first:border-t-0 sm:w-fit">
                                        <div onClick={() => navigate(`/user-centered-dashboard/${_d?.id}`)} className="w-1/5 text-sm text-[#0881FC] font-medium px-4 py-3 cursor-pointer sm:w-[100px] sm:min-w-[100px] sm:[&:nth-child(1)]:sticky sm:[&:nth-child(1)]:left-0 sm:[&:nth-child(1)]:bg-white">{_d?.id}</div>
                                        <div className="w-[15%] text-sm text-[#3A3A3A] px-4 py-3 sm:w-[100px] sm:min-w-[100px]">{_d?.totalsession}</div>
                                        <div className="w-[18%] text-sm text-[#3A3A3A] px-4 py-3 sm:w-[100px] sm:min-w-[100px]">{_d?.avgvisitduration}</div>
                                        <div className="w-[18%] text-sm text-[#3A3A3A] px-4 py-3 sm:w-[100px] sm:min-w-[100px]">{_d?.ordercount}</div>
                                        <div className="w-[15%] text-sm text-[#3A3A3A] px-4 py-3 sm:w-[100px] sm:min-w-[100px]">{formatRevenue(_d?.revenuegenerated || 0)}</div>
                                        <div className="w-[14%] text-sm text-[#3A3A3A] px-4 py-3 sm:w-[100px] sm:min-w-[100px]">{platformMapper(_d?.refeerer)}</div>
                                    </div>
                                )

                            )
                                : (
                                    <NoresultFound />
                                )
                        // </div>
                        :
                        <TopActiveUserSkeleton />
                    }
                </div>
            </div>
        </>
    )
}
export default TopActiveUser;