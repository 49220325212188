const CategoryDetailsBlockSkeleton = () => {
    return (<div className="w-[375px] border border-[#DEDEDE] bg-white p-10 rounded-xl min-h-[296px]">
        <div className="w-[144px] h-[142px] bg-white mx-auto rounded-xl skeleton-container">
            <div className="skeleton-content"></div>
        </div>
        <p className="h-[15px] w-[60%] text-center bg-white mt-6 mx-auto skeleton-container">
            <div className="skeleton-content"></div>
        </p>
        <p className="h-[15px] w-[100%] text-center bg-white mt-2 skeleton-container">
            <div className="skeleton-content"></div>
        </p>
    </div>)
}

export default CategoryDetailsBlockSkeleton