import React from 'react'

const ACTIVE_TOP_USER_SKELETION_ROW_SIZE = new Array(7).fill(0)

const TopActiveUserSkeleton = () => {
    return (<>
        <div className="w-full">
            {/* <div className="flex items-center justify-between">
                    <div className="flex">
                        <h1 className="h-[15px] w-[120px] skeleton-container mr-3">
                            <span className="skeleton-content"></span>
                        </h1>
                        <span className="skeleton-container w-[110px] h-4">
                            <span className="skeleton-content"></span>
                        </span>
                    </div>
                    <div>
                        <p className="skeleton-container w-[110px] h-4">
                            <span className="skeleton-content"></span>
                        </p>
                    </div>
                </div> */}
            {ACTIVE_TOP_USER_SKELETION_ROW_SIZE.map((d, index) =>
                <div key={index} className="border border-[#DEDEDE] mt-4 bg-white rounded-xl rounded-bl-none rounded-br-none">
                    <div className="flex">
                        <div className="w-1/5 text-sm font-medium text-[#3A3A3A] px-4 py-3">
                            <p className="skeleton-container h-4 bg-[#f2f2f2] w-[100%]">
                                <span className="skeleton-content"></span>
                            </p>
                        </div>
                        <div className="w-[15%] text-sm font-medium text-[#3A3A3A] px-4 py-3">
                            <p className="skeleton-container h-4 bg-[#f2f2f2] w-[100%]">
                                <span className="skeleton-content"></span>
                            </p>
                        </div>
                        <div className="w-[18%] text-sm font-medium text-[#3A3A3A] px-4 py-3">
                            <p className="skeleton-container h-4 bg-[#f2f2f2] w-[100%]">
                                <span className="skeleton-content"></span>
                            </p>
                        </div>
                        <div className="w-[18%] text-sm font-medium text-[#3A3A3A] px-4 py-3">
                            <p className="skeleton-container h-4 bg-[#f2f2f2] w-[100%]">
                                <span className="skeleton-content"></span>
                            </p>
                        </div>
                        <div className="w-[15%] text-sm font-medium text-[#3A3A3A] px-4 py-3">
                            <p className="skeleton-container h-4 bg-[#f2f2f2] w-[100%]">
                                <span className="skeleton-content"></span>
                            </p>
                        </div>
                        <div className="w-[14%] text-sm font-medium text-[#3A3A3A] px-4 py-3">
                            <p className="skeleton-container h-4 bg-[#f2f2f2] w-[100%]">
                                <span className="skeleton-content"></span>
                            </p>
                        </div>
                    </div>
                </div>
            )}
        </div>
    </>)
}

export default TopActiveUserSkeleton