import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SearchLineIcon from "remixicon-react/SearchLineIcon";
import { identifyString, removeSpecialCharacters } from "../../utils/helperFunctions";
import { getUserInfo, isbrandCategory } from "../../core/actions/analytic.action";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { ALERT_MESSAGES, INFO_MESSAGES, L1_CATEGORY_IDS } from "../../config/constant";
import { logout } from "../../core/actions/auth.action";

const CommonHeader = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { user } = useSelector((s) => s.authStore)

    let [searchInput, setSearchInput] = useState("")
    const [showLogoutPopUp, setShowLogoutPopUp] = useState(false);


    const handleSearhInputKeyPress = (e) => {
        if (e.key === 'Enter') {
            searchBtnClickHandler()
        }
    }

    const logoutHandler = () => {
        dispatch(logout(() => {
            toast.success(INFO_MESSAGES.LOGOUT_SUCCESSFULLY)
        }))
    }

    const searchBtnClickHandler = () => {
        searchInput = removeSpecialCharacters(searchInput)
        const ref = identifyString(searchInput);
        let isUser = false;
        let isCategory = false;

        if (["EMAIL", "PHONE_NUMBER", "USER_ID"].includes(ref)) {
            isUser = true;
        } else {
            isCategory = true;
        }

        if (isUser) {
            dispatch(getUserInfo(`searchTerm=${searchInput}`, (user) => {
                navigate(`/user-centered-dashboard/${user.id}`);
            }));
        } else if (isCategory) {
            dispatch(isbrandCategory(`str=${searchInput}`, (categoryInfo) => {
                if (categoryInfo.type === "category") {
                    const categoryCode = (categoryInfo?.redirectionLink?.split('/') || []).pop();
                    if (L1_CATEGORY_IDS.includes(categoryCode)) {
                        toast.error(ALERT_MESSAGES.PLEASE_SEARCH_L1_OR_L2_CATEGORY)
                        return
                    }
                    navigate(`/category-centered-dashboard/${categoryCode}`);
                } else {
                    toast.error('Invalid category');
                }
            }));
        } else {
            toast.error('Invalid search string');
        }
    };


    const searchPageNavigateHandler = () => {
        navigate("/search")
    }

    return (
        <>
            <div className="w-full p-3.5 fixed z-20 bg-white flex items-center shadow-[0_2px_3px_rgba(0,0,0,.1)]">
                <div className="flex items-center ">
                    <img src="/usershher-img.svg" className="w-[186px] mr-10 cursor-pointer" onClick={searchPageNavigateHandler}></img>
                    <div className='relative w-[536px]'>
                        <input onKeyPress={handleSearhInputKeyPress} value={searchInput} onChange={(e) => setSearchInput(e.target.value)} type="search"
                            placeholder='Search for Phone Number, Email ID, Category Name'
                            className='relative border border-[#808080] text-sm w-full rounded-[60px] focus:outline-none focus:ring-0 focus:border-searchBorder py-2 pl-8  h-[52px]' />
                        <SearchLineIcon size={16} className="absolute left-2.5 bottom-[18px] text-[#BFBFBF]" />
                        <div onClick={searchBtnClickHandler} className='bg-[#0B66F8] rounded-full h-[46px] w-[46px] absolute flex items-center justify-center right-[3px] bottom-[3px] cursor-pointer'>
                            <SearchLineIcon size={16} className='text-white' />
                        </div>
                        {/* <SearchPopup /> */}
                    </div>
                </div>
                <div className="flex items-center ml-auto">
                    <div className="text-end pr-4">
                        <p className='text-xs text-[#3A3A3A] leading-none'>{user?.firstName + user?.lastName || "User"}</p>
                        <span className='text-[10px] text-[#ADADAD]'>{user?.email || "user@gmail.com"}</span>
                    </div>
                    <div className="relative" onClick={() => setShowLogoutPopUp(!showLogoutPopUp)}>
                        <img src="/user-icon.png" className="h-[34px] min-w-[34px]"></img>
                        {showLogoutPopUp && (
                            <div className="w-[100px] absolute bg-white border border-[#BEBEBE] right-1 rounded-lg" onClick={logoutHandler}>
                                <p className="text-sm p-3 rounded-lg text-center bg-[#EFF6F8] font-semibold">Logout</p>
                            </div>
                        )}

                    </div>
                </div>
            </div>
        </>
    )
}
export default CommonHeader;