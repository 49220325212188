import React, { useEffect, useState } from "react";
import CommonHeader from "../../components/CommonHeader";
import DashboardAnalytics from "../../components/DashboardAnalytics";
import Filter from "../../components/Filter";
import JourneyExplore from "../../components/JourneyExplore";
import PdpVisited from "../../components/PdpVisited";
import UserCategoryVisited from "../../components/UserCategoryVisited";
import UserPlatform from "../../components/UserPlatform";
import UserProfile from "../../components/UserProfile";
import { useParams } from "react-router-dom";
import { DEFAULT_DEVICE, DEFAULT_TIME_FRAME } from "../../../config/constant";
import moment from "moment";
import UserTopSearches from "../../components/UserPage/UserTopSearches";


const UserCenteredDashboard = () => {
    const params = useParams();

    const [searchCriteria, setSearchCriteria] = useState({
        device: DEFAULT_DEVICE,
        userId: params.userId,
        lastNDays: DEFAULT_TIME_FRAME,
        startDate: moment().subtract(DEFAULT_TIME_FRAME, 'days').format('YYYY-MM-DD'), //TODAY - DEFAULT_TIME_FRAME
        endDate: moment().format('YYYY-MM-DD')
    })

    useEffect(() => {
        if (params.userId && params.userId !== searchCriteria.userId) {
            setSearchCriteria(prevSearchCriteria => ({
                ...prevSearchCriteria,
                userId: params.userId

            }))
        }
    }, [params, searchCriteria.userId])

    return (
        <>
            <CommonHeader />
            <div className="py-6 mx-4 pt-[100px]">
                <Filter searchCriteria={searchCriteria} setSearchCriteria={setSearchCriteria} />
                <div className="flex pt-5 w-full sm:hidden">
                    <div className="left-container w-[375px] mr-5">
                        <UserProfile userId={params.userId} />
                        <JourneyExplore searchCriteria={searchCriteria} setSearchCriteria={setSearchCriteria} />
                        <UserPlatform searchCriteria={searchCriteria} setSearchCriteria={setSearchCriteria} />
                    </div>
                    <div className="Right-container w-[calc(100%-375px)]">
                        <DashboardAnalytics searchCriteria={searchCriteria} setSearchCriteria={setSearchCriteria} />
                        <PdpVisited searchCriteria={searchCriteria} />
                        <UserCategoryVisited searchCriteria={searchCriteria} />
                        <UserTopSearches searchCriteria={searchCriteria} />
                    </div>
                </div>
                <div className="pt-5 w-full md:hidden">
                    <UserProfile userId={params.userId} />
                    <DashboardAnalytics searchCriteria={searchCriteria} setSearchCriteria={setSearchCriteria} />
                    <JourneyExplore searchCriteria={searchCriteria} setSearchCriteria={setSearchCriteria} />
                    <PdpVisited searchCriteria={searchCriteria} />
                    <UserCategoryVisited searchCriteria={searchCriteria} />
                    <UserPlatform searchCriteria={searchCriteria} setSearchCriteria={setSearchCriteria} />
                    <UserTopSearches searchCriteria={searchCriteria} />
                </div>
            </div>
        </>
    );
};

export default UserCenteredDashboard;
