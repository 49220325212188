import { useDispatch, useSelector } from "react-redux"
import { getOrderFunnel, getUserJourney } from "../../core/actions/analytic.action"
import { useEffect } from "react"
import queryString from "query-string"
import { DEFAULT_TIME_FRAME_OPTIONS } from '../../config/constant'
import moment from "moment"
import { journeyExplorerPageMapper } from "../../utils/helperFunctions"

const JourneyExplore = ({ searchCriteria, setSearchCriteria }) => {
    const { userJourney, orderFunnel } = useSelector((s) => s.analyticStore)
    const dispatch = useDispatch()

    useEffect(() => {
        // update startDate and endDate to DD-MM-YYYY
        const clonedSearchCriteria = { ...searchCriteria }
        clonedSearchCriteria.startDate = moment(clonedSearchCriteria.startDate).format("DD-MM-YYYY")
        clonedSearchCriteria.endDate = moment(clonedSearchCriteria.endDate).format("DD-MM-YYYY")

        const stringifiedSearchCriteria = queryString.stringify(clonedSearchCriteria);
        dispatch(getUserJourney(stringifiedSearchCriteria, null))
        dispatch(getOrderFunnel(stringifiedSearchCriteria, null))
    }, [searchCriteria])


    return (
        <>
            <div className="border border-[#DEDEDE] p-4 bg-white rounded-xl mt-5">
                <div className="flex items-center justify-between">
                    <h1 className="font-bold text-lg leading-6">Journey Explorer</h1>
                    <select onChange={(e) => setSearchCriteria({ ...searchCriteria, lastNDays: e.target.value })} className="w-[110px] py-1.5 px-2.5 border border-[#BFBFBF] rounded-md text-xs bg-white text-[#0B66F8] font-semibold focus:outline-none focus:ring-0 cursor-pointer">
                        {DEFAULT_TIME_FRAME_OPTIONS?.map((_d, index) =>
                            <option selected={_d === searchCriteria.lastNDays} value={_d} key={index}>Last {_d} days</option>
                        )}
                    </select>
                </div>
                <div className="border border-[#DEDEDE] p-4 bg-white rounded-xl mt-4 max-h-[305px] overflow-auto">
                    {userJourney.map((_userJourney, index) =>
                        <div key={index}>
                            <div className="flex w-full">
                                <span className="h-3 w-3 bg-[#FF9D00] rounded-full"></span>
                                <div className="flex w-full ml-3 justify-between">
                                    <p>
                                        <p className="text-sm text-[#3A3A3A] font-medium leading-none">{journeyExplorerPageMapper(_userJourney.pagetype)}</p>
                                        <a target="_blank" href={_userJourney?.url} className="text-[10px] text-[#0881FC]">
                                            {_userJourney?.url?.substring(0, 30)}...
                                        </a>
                                    </p>
                                    <p className="text-[10px] text-[#7E7E7E]">{_userJourney?.date}</p>
                                </div>
                            </div>
                            {/* <span className="border border-[#FF9D00] h-10 ml-1 -mt-3 inline-block last:border-none"></span> */}

                            {index !== userJourney.length - 1 && (
                                <span className="border border-[#FF9D00] h-10 ml-1 -mt-5 inline-block"></span>
                            )}
                        </div>
                    )}
                </div>

                {/* order-funnel */}
                <h1 className="font-bold text-lg leading-6 w-full mt-4">Order Item Funnel</h1>
                <div className="border border-[#DEDEDE] p-4 rounded-xl mt-4">
                    <ul>
                        <li className="flex items-center w-full justify-between mt-5 first:mt-0">
                            <p className="text-sm text-[#3A3A3A] flex w-[180px] justify-between mr-7">
                                <span>Total Items</span>
                                <span>{orderFunnel?.totalorder}</span>
                            </p>
                            <span className="h-4 rounded-md w-[160px] bg-[#DEDEDE] relative">
                                <span className="bg-[#CE6C09] absolute left-0 h-full w-full rounded-md"></span>
                            </span>
                        </li>
                        <li className="flex items-center w-full justify-between mt-5 first:mt-0">
                            <p className="text-sm text-[#3A3A3A] flex w-[180px] justify-between mr-7">
                                <span>Items Placed</span>
                                <span>{orderFunnel?.orderplaced}</span>
                            </p>
                            <span className="h-4 rounded-md w-[160px] bg-[#DEDEDE] relative">
                                <span className="bg-[#FF9E00] absolute left-0 h-full w-1/2 rounded-md"></span>
                            </span>
                        </li>
                        <li className="flex items-center w-full justify-between mt-5 first:mt-0">
                            <p className="text-sm text-[#3A3A3A] flex w-[180px] justify-between mr-7">
                                <span>Items In Transit</span>
                                <span>{orderFunnel?.orderintransit}</span>
                            </p>
                            <span className="h-4 rounded-md w-[160px] bg-[#DEDEDE] relative">
                                <span className="bg-[#3E97FF] absolute left-0 h-full w-1/2 rounded-md"></span>
                            </span>
                        </li>
                        <li className="flex items-center w-full justify-between mt-5 first:mt-0">
                            <p className="text-sm text-[#3A3A3A] flex w-[180px] justify-between mr-7">
                                <span>Items Delivered</span>
                                <span>{orderFunnel?.orderdelivered}</span>
                            </p>
                            <span className="h-4 rounded-md w-[160px] bg-[#DEDEDE] relative">
                                <span className="bg-[#2DB70C] absolute left-0 h-full w-1/2 rounded-md"></span>
                            </span>
                        </li>
                        <li className="flex items-center w-full justify-between mt-5 first:mt-0">
                            <p className="text-sm text-[#3A3A3A] flex w-[180px] justify-between mr-7">
                                <span>Items Returned</span>
                                <span>{orderFunnel?.orderreturned}</span>
                            </p>
                            <span className="h-4 rounded-md w-[160px] bg-[#DEDEDE] relative">
                                <span className="bg-[#D9232D] absolute left-0 h-full w-1/2 rounded-md"></span>
                            </span>
                        </li>
                        <li className="flex items-center w-full justify-between mt-5 first:mt-0">
                            <p className="text-sm text-[#3A3A3A] flex w-[180px] justify-between mr-7">
                                <span>Items Cancel</span>
                                <span>{orderFunnel?.ordercanceled}</span>
                            </p>
                            <span className="h-4 rounded-md w-[160px] bg-[#DEDEDE] relative">
                                <span className="bg-[#c46d04] absolute left-0 h-full w-1/2 rounded-md"></span>
                            </span>
                        </li>
                    </ul>
                </div>
            </div>

            {/* skeleton */}

            {/* <div className="border border-[#DEDEDE] p-4 bg-white rounded-xl mt-5">
                <div className="flex items-center justify-between">
                    <h1 className="h-[15px] w-[40%] skeleton-container">
                        <span className="skeleton-content"></span>
                    </h1>
                    <span className="skeleton-container w-[110px] h-4">
                        <span className="skeleton-content"></span>
                    </span>
                </div>
                <div className="border border-[#DEDEDE] p-4 bg-white rounded-xl mt-4 max-h-[305px] overflow-auto">

                    <div >
                        <div className="flex w-full">
                            <span className="h-3 w-3 bg-[#FF9D00] rounded-full"></span>
                            <div className="flex w-full ml-3 justify-between">
                                <p>
                                    <p className="skeleton-container w-[110px] h-3">
                                        <span className="skeleton-content"></span>
                                    </p>
                                    <a target="_blank" href="" className="skeleton-container w-[110px] mt-1 h-3 block ">
                                        <span className="skeleton-content"></span>
                                    </a>
                                </p>
                                <p className="skeleton-container w-[110px] h-3">
                                    <span className="skeleton-content"></span>
                                </p>
                            </div>
                        </div>
                    </div>

                </div>

                <h1 className="h-[15px] w-[40%] skeleton-container mt-4">
                    <span className="skeleton-content"></span>
                </h1>
                <div className="border border-[#DEDEDE] p-4 rounded-xl mt-4">
                    <ul>
                        <li className="flex items-center w-full justify-between mt-5 first:mt-0">
                            <p className="text-sm text-[#3A3A3A] flex w-[180px] justify-between mr-7">
                                <span className="skeleton-container w-[110px] h-4 mr-5">
                                    <span className="skeleton-content"></span>
                                </span>
                                <span className="skeleton-container w-[40px] h-4">
                                    <span className="skeleton-content"></span>
                                </span>
                            </p>
                            <span className="h-4 rounded-md w-[160px] bg-[#DEDEDE] relative">
                            </span>
                        </li>
                        <li className="flex items-center w-full justify-between mt-5 first:mt-0">
                            <p className="text-sm text-[#3A3A3A] flex w-[180px] justify-between mr-7">
                                <span className="skeleton-container w-[110px] h-4 mr-5">
                                    <span className="skeleton-content"></span>
                                </span>
                                <span className="skeleton-container w-[40px] h-4">
                                    <span className="skeleton-content"></span>
                                </span>
                            </p>
                            <span className="h-4 rounded-md w-[160px] bg-[#DEDEDE] relative">
                            </span>
                        </li>
                        <li className="flex items-center w-full justify-between mt-5 first:mt-0">
                            <p className="text-sm text-[#3A3A3A] flex w-[180px] justify-between mr-7">
                                <span className="skeleton-container w-[110px] h-4 mr-5">
                                    <span className="skeleton-content"></span>
                                </span>
                                <span className="skeleton-container w-[40px] h-4">
                                    <span className="skeleton-content"></span>
                                </span>
                            </p>
                            <span className="h-4 rounded-md w-[160px] bg-[#DEDEDE] relative">
                            </span>
                        </li>
                        <li className="flex items-center w-full justify-between mt-5 first:mt-0">
                            <p className="text-sm text-[#3A3A3A] flex w-[180px] justify-between mr-7">
                                <span className="skeleton-container w-[110px] h-4 mr-5">
                                    <span className="skeleton-content"></span>
                                </span>
                                <span className="skeleton-container w-[40px] h-4">
                                    <span className="skeleton-content"></span>
                                </span>
                            </p>
                            <span className="h-4 rounded-md w-[160px] bg-[#DEDEDE] relative">
                            </span>
                        </li>
                        <li className="flex items-center w-full justify-between mt-5 first:mt-0">
                            <p className="text-sm text-[#3A3A3A] flex w-[180px] justify-between mr-7">
                                <span className="skeleton-container w-[110px] h-4 mr-5">
                                    <span className="skeleton-content"></span>
                                </span>
                                <span className="skeleton-container w-[40px] h-4">
                                    <span className="skeleton-content"></span>
                                </span>
                            </p>
                            <span className="h-4 rounded-md w-[160px] bg-[#DEDEDE] relative">
                            </span>
                        </li>
                    </ul>
                </div>
            </div > */}
        </>
    )
}
export default JourneyExplore;