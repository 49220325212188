import { useState } from "react"
import { ALERT_MESSAGES, ENVIRONMENT, INFO_MESSAGES } from "../../config/constant"
import axios from "axios"
import toast from "react-hot-toast"
import { convertObjectKeysToLowerCase, convertToCSV, downloadCSV } from "../../utils/helperFunctions"
import { useLocation } from "react-router-dom"
import apis from '../../config/apis'

const Filter = ({ searchCriteria, setSearchCriteria }) => {
    const ENV = process.env.REACT_APP_ENVIRONMENT

    const [lastNDays, setLastNDays] = useState("2")
    const [minVisits, setMinVisits] = useState("3")
    const [device, setDevice] = useState("web")
    const [loader, setLoader] = useState(false)
    const location = useLocation();

    const downloadInsightHandler = () => {
        setLoader(true)
        axios.get(`${apis.DOWNLOAD_USER_VISIT_BY_CATEGORY}category=${searchCriteria?.category}&device=${device}&lastNDays=${lastNDays}&minVisits=${minVisits}`).then((response) => {
            setLoader(false)
            let data = response.data.data
            if (data.length === 0) {
                toast.error(INFO_MESSAGES.DATA_NOT_FOUND)
                return
            };

            let mappedData = []

            for (let d of data) {
                d = convertObjectKeysToLowerCase(d)
                for (let product of d.productlist) {
                    product = convertObjectKeysToLowerCase(product)
                }
                mappedData.push(d)
            }
            // Assuming the API response contains the data
            const csvContent = convertToCSV(mappedData);
            downloadCSV(csvContent, 'user_visit_data.csv');
        }).catch((err) => {
            setLoader(false)
            console.log(err)
            toast.error(ALERT_MESSAGES.SOMETHING_WENT_WRONG)
        })
    }

    return (
        <>
            <div className="flex items-end border-b border-[#BEBEBE] pb-4 sm:flex-wrap sm:overflow-auto">
                <div className="flex">
                    <div className="mr-3">
                        <select onChange={(e) => setSearchCriteria({ ...searchCriteria, device: e.target.value })} className="bg-white h-[33px] border w-[127px] border-[#DEDEDE] rounded-md py-2 px-2 text-xs cursor-pointer">
                            <option value={"web"} >WEB</option>
                            <option value={"app"}>APP</option>
                        </select>
                    </div>
                    <div className="mr-3">
                        <input value={searchCriteria.startDate} onChange={(e) => setSearchCriteria({ ...searchCriteria, startDate: e.target.value })} type="date" placeholder="Start Date" className="w-[127px] h-[33px] text-xs border border-[#DEDEDE] rounded-md py-2 px-2 focus:outline-none" />
                    </div>
                    <div className="mr-3">
                        <input value={searchCriteria.endDate} onChange={(e) => setSearchCriteria({ ...searchCriteria, endDate: e.target.value })} type="date" placeholder="End Date" className="w-[127px] h-[33px] text-xs border border-[#DEDEDE] rounded-md py-2 px-2 focus:outline-none" />
                    </div>
                </div>

                {/* Mount only for category centered dashboard */}
                {location.pathname.includes("category-centered-dashboard") && < div className="ml-auto flex items-end sm:mt-4 sm:ml-0">
                    <div className="">
                        <p className="text-xs pb-1">Enter Days</p>
                        <input value={lastNDays} onChange={(e) => setLastNDays(e.target.value)} numbe type="number" placeholder="Enter Days" className="mr-4 h-[33px] border w-[127px] border-[#DEDEDE] p-2 focus:outline-none focus:ring-0 text-xs rounded" />
                    </div>
                    <div className="">
                        <p className="text-xs pb-1">Enter Min Visits</p>
                        <input value={minVisits} onChange={(e) => setMinVisits(e.target.value)} type="number" placeholder="Enter Min Visits" className="mr-4 h-[33px] border w-[127px] border-[#DEDEDE] p-2 focus:outline-none focus:ring-0 text-xs rounded" />
                    </div>
                    <button
                        className="w-full min-w-fit py-1 h-[33px] px-3 bg-blue-500 text-white font-semibold rounded shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75 text-sm" // Adjusted styles
                        type="button"
                        onClick={downloadInsightHandler}
                    >
                        {loader ? "Downloading.." : "Download Insights"}
                    </button>
                </div>}
            </div >
        </>
    )
}
export default Filter;