const authActionTypes = {
    SET_USER_LOADER: "SET_USER_LOADER",
    SET_USER_PROFILE: "SET_USER_PROFILE",
    SET_IS_AUTHENTICATED: "SET_IS_AUTHENTICATED",
    SET_USER: "SET_USER",
    SET_CURRENT_CITY: "SET_CURRENT_CITY",
    SET_IS_NEW_USER: "SET_IS_NEW_USER",
    SET_USER_LOGOUT: "SET_USER_LOGOUT",
    SET_CUSTOMER_WALLET: "SET_CUSTOMER_WALLET",
    SET_CUSTOMER_WALLET_TRANSACTIONS: "SET_CUSTOMER_WALLET_TRANSACTIONS",
    SET_WELCOME_REFERRAL_CODE: "SET_WELCOME_REFERRAL_CODE",
    SET_LOCAL_STORAGE: "SET_LOCAL_STORAGE",
    SET_CUSTOMER_ADDRESS: "SET_CUSTOMER_ADDRESS"
}

export default authActionTypes