import React from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import ShareBoxFillIcon from "remixicon-react/ShareBoxFillIcon";


const SearchPopup = ({ recentSearches }) => {
    const navigate = useNavigate()
    const navigationHandler = (ref) => {
        if (ref.userId) {
            navigate(`/user-centered-dashboard/${ref.userId}`)
        }
        else if (ref.categoryId) {
            navigate(`/category-centered-dashboard/${ref.categoryId}`)
        }
        else {
            toast.error('Unable to map, Please search again')
        }
    }
    return (
        <>
            <div className='absolute w-full bg-white rounded-2xl border border-[#DEDEDE] shadow-md'>
                <div className='max-h-[290px] overflow-scroll scrollbar-hide'>
                    <p className="text-[#8A8A8A] text-xs px-2.5 pt-2.5 mb-1">Category</p>
                    {recentSearches?.categoryBasedSearchHistory?.reverse().map((_d, index) =>
                        <div onClick={() => navigationHandler(_d)} key={index} className="p-2.5 flex cursor-pointer hover:bg-[#EFF6F8]">
                            <p className='text-sm text-[#3A3A3A] leading-none'>{_d.categoryId} ({_d.categoryName})</p>
                            <ShareBoxFillIcon  size={18} className="ml-auto font-semibold text-[#0B66F8] -rotate-90" />
                        </div>
                    )}
                    <p className="text-[#8A8A8A] text-xs px-2.5 pt-2.5 mb-1">Customer</p>
                    {recentSearches?.userBasedSearchHistory?.reverse().map((_d, index) =>
                        <div onClick={() => navigationHandler(_d)} className='p-2.5 flex cursor-pointer hover:bg-[#EFF6F8]'>
                            <div className=''>
                                <p className='text-sm font-medium text-[#3A3A3A] leading-none'>{_d?.userName} (C ID: {_d?.userId})</p>
                                <span className='text-xs text-[#3A3A3A]'>{_d?.email}</span>
                            </div>
                            <ShareBoxFillIcon  size={18} className="ml-auto font-semibold text-[#0B66F8] -rotate-90" />
                        </div>
                    )}
                </div>

                {/* Recent Search */}


                {/* <div className='max-h-[290px] overflow-scroll'>
                    <p className="text-xs text-[#3C3C3C] px-2.5 pt-2.5 font-medium">
                        Recent Search
                    </p>
                    <div className="flex flex-wrap">
                        <span className="bg-[#0B66F8] text-xs rounded py-2 px-3 text-white mt-4 mx-2">
                            Amit Mishra
                        </span>
                        <span className="bg-[#0B66F8] text-xs rounded py-2 px-3 text-white mt-4 mx-2">
                            ID4532SHS
                        </span>
                    </div>
                </div> */}




                {/* skeleton */}

                {/* <div className='absolute w-full bg-white rounded-2xl border border-[#DEDEDE] shadow-md'>
                    <div className='max-h-[290px] overflow-scroll'>
                        <p className="text-[#8A8A8A] text-xs px-2.5 pt-2.5">Category</p>
                        <div className="p-2.5 flex">
                            <p className='skeleton-container h-3 w-[250px]'>
                                <span className="skeleton-content"></span>
                            </p>
                            <p className='skeleton-container ml-auto h-3 w-4'>
                                <span className="skeleton-content"></span>
                            </p>
                        </div>
                        <p className="text-[#8A8A8A] text-xs px-2.5 pt-2.5">Customer</p>
                        <div className='p-2.5 flex'>
                            <div className=''>
                                <p className='skeleton-container h-3 w-[250px]'>
                                    <span className="skeleton-content"></span>
                                </p>
                                <p className='skeleton-container mt-1 h-3 w-[200px]'>
                                    <span className="skeleton-content"></span>
                                </p>
                            </div>
                            <p className='skeleton-container ml-auto h-3 w-4'>
                                <span className="skeleton-content"></span>
                            </p>
                        </div>

                    </div>
                </div> */}

            </div>
        </>
    )
}
export default React.memo(SearchPopup);