
const UserCategoryVisitedSkeleton = () => {
    return (<div className="border border-[#DEDEDE] bg-white rounded-xl rounded-tl-none rounded-tr-none max-h-[436px] overflow-auto">
        <div className="flex p-4 border-t border-[#DEDEDE] first:border-t-0">
            <div className="w-3/5 flex pr-4">
                <div className="w-[54px] h-[54px] min-w-[54px] skeleton-container">
                    <span className="skeleton-content"></span>
                </div>
                <div className="pl-4 w-full">
                    <p className="w-[80px] h-3 skeleton-container">
                        <span className="skeleton-content"></span>
                    </p>
                    <p className="w-[80%] h-3 skeleton-container mt-1">
                        <span className="skeleton-content"></span>
                    </p>
                    <p className="w-[40%] h-3 skeleton-container mt-0.5">
                        <span className="skeleton-content"></span>
                    </p>
                </div>
            </div>
            <div className="w-1/5 px-4">
                <p className="skeleton-container h-3 bg-[#f2f2f2] w-[60%]">
                    <span className="skeleton-content"></span>
                </p>
            </div>
            <div className="w-1/5">
                <p className="skeleton-container h-3 bg-[#f2f2f2] w-[60%]">
                    <span className="skeleton-content"></span>
                </p>
            </div>
        </div>
    </div>)
}

export default UserCategoryVisitedSkeleton