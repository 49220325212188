import React from "react";

import { Toaster } from "react-hot-toast";
import PublicRoutes from "./web/routes/PublicRoutes";
import ProtectedRoutes from "./web/routes/ProtectedRoutes";
import './index.scss'
function App() {
  return (
    <>
      <React.Suspense>
        <Toaster position="top-right"
          reverseOrder={false} />
        <PublicRoutes />
        <ProtectedRoutes />
      </React.Suspense>
    </>
  );
}

export default App;
