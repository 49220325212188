const CategoryBuyersSkeleton = () => {
    return (<div className="w-full mt-5">
        <div className="flex items-center justify-between">
            <h1 className="h-4 w-[30%] skeleton-container ">
                <span className="skeleton-content"></span>
            </h1>
            <span className="skeleton-container w-[110px] h-4">
                <span className="skeleton-content"></span>
            </span>
        </div>
        <div className="border border-[#DEDEDE] mt-4 bg-white rounded-xl rounded-bl-none rounded-br-none">
            <div className="flex">
                <div className="w-1/5 px-4 py-3">
                    <p className="skeleton-container h-4 bg-[#f2f2f2] w-[75%]">
                        <span className="skeleton-content"></span>
                    </p>
                </div>
                <div className="w-[15%] px-4 py-3">
                    <p className="skeleton-container h-4 bg-[#f2f2f2] w-[70%]">
                        <span className="skeleton-content"></span>
                    </p>
                </div>
                <div className="w-[18%] px-4 py-3">
                    <p className="skeleton-container h-4 bg-[#f2f2f2] w-[70%]">
                        <span className="skeleton-content"></span>
                    </p>
                </div>
                <div className="w-[18%] px-4 py-3">
                    <p className="skeleton-container h-4 bg-[#f2f2f2] w-[70%]">
                        <span className="skeleton-content"></span>
                    </p>
                </div>
                <div className="w-[15%] px-4 py-3">
                    <p className="skeleton-container h-4 bg-[#f2f2f2] w-[70%]">
                        <span className="skeleton-content"></span>
                    </p>
                </div>
                <div className="w-[14%] px-4 py-3">
                    <p className="skeleton-container h-4 bg-[#f2f2f2] w-[70%]">
                        <span className="skeleton-content"></span>
                    </p>
                </div>
            </div>
        </div>
        <div className="border border-[#DEDEDE] bg-white rounded-xl rounded-tl-none rounded-tr-none max-h-[226px] overflow-auto">
            <div className="flex border-t border-[#DEDEDE] first:border-t-0">
                <div className="w-1/5 text-sm text-[#0881FC] font-medium px-4 py-3 cursor-pointer">
                    <p className="skeleton-container h-3 bg-[#f2f2f2] w-[65%]">
                        <span className="skeleton-content"></span>
                    </p>
                </div>
                <div className="w-[15%] text-sm text-[#3A3A3A] px-4 py-3">
                    <p className="skeleton-container h-3 bg-[#f2f2f2] w-[60%]">
                        <span className="skeleton-content"></span>
                    </p>
                </div>
                <div className="w-[18%] text-sm text-[#3A3A3A] px-4 py-3">
                    <p className="skeleton-container h-3 bg-[#f2f2f2] w-[60%]">
                        <span className="skeleton-content"></span>
                    </p>
                </div>
                <div className="w-[18%] text-sm text-[#3A3A3A] px-4 py-3">
                    <p className="skeleton-container h-3 bg-[#f2f2f2] w-[60%]">
                        <span className="skeleton-content"></span>
                    </p>
                </div>
                <div className="w-[15%] text-sm text-[#3A3A3A] px-4 py-3">
                    <p className="skeleton-container h-3 bg-[#f2f2f2] w-[60%]">
                        <span className="skeleton-content"></span>
                    </p>
                </div>
                <div className="w-[14%] text-sm text-[#3A3A3A] px-4 py-3">
                    <p className="skeleton-container h-3 bg-[#f2f2f2] w-[60%]">
                        <span className="skeleton-content"></span>
                    </p>
                </div>
            </div>
        </div>
    </div>)
}

export default CategoryBuyersSkeleton