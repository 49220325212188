import axios from 'axios'
import apis from '../../config/apis'
import analyticActionTypes from '../actionTypes/analytic.actionType';
import toast from 'react-hot-toast';

import { ALERT_MESSAGES } from '../../config/constant';
import { convertKeysToLowerCase, convertObjectKeysToLowerCase } from '../../utils/helperFunctions';

const loader = (type, data) => {
    return {
        type: type,
        payload: data
    }
}

//SAVE RECENT SEARCH
export const saveRecentSearch = (_data, _cb) => {
    return async (dispatch) => {
        try {
            const { data } = await axios({
                method: "Post",
                url: apis.SAVE_RECENT_SEARCH,
                data: _data
            })
            dispatch({
                type: analyticActionTypes.SET_RECENT_SEARCHES,
                payload: data.data
            })
            _cb && _cb()
            return
        }
        catch (err) {
            console.log(`method: saveRecentSearch: exceptionError ${err}`)
            toast.error(ALERT_MESSAGES.SOMETHING_WENT_WRONG)
        }
    }
}


//GET RECENT SEARCH
export const getRecentSearch = (_params, _cb) => {
    return async (dispatch) => {
        try {
            // dispatch(loader(true))
            const { data } = await axios({
                method: "Get",
                url: apis.GET_RECENT_SEARCH + _params
            })
            // dispatch(loader(false))
            if (data.data) {
                dispatch({
                    type: analyticActionTypes.SET_RECENT_SEARCHES,
                    payload: data.data
                })
                _cb && _cb()
            }
            return
        }
        catch (err) {
            console.log(`method: getRecentSearch: exceptionError ${err}`)
            // dispatch(loader(false))
            toast.error(ALERT_MESSAGES.SOMETHING_WENT_WRONG)
        }
    }
}

//GET USER INFO
export const getUserInfo = (_params, _cb) => {
    return async (dispatch) => {
        try {
            dispatch(loader(analyticActionTypes.GET_USER_INFO_API_LOADER, true))
            const { data } = await axios({
                method: "Get",
                url: apis.GET_USER_DETAILS + _params
            })
            dispatch(loader(analyticActionTypes.GET_USER_INFO_API_LOADER, false))
            if (data.status && data.code === 200) {
                dispatch({
                    type: analyticActionTypes.SET_USER_INFO,
                    payload: convertObjectKeysToLowerCase(data.data)
                })
                _cb && _cb(data.data)
                return
            }
            toast.error(ALERT_MESSAGES.USER_NOT_FOUND)
            return
        }
        catch (err) {
            dispatch(loader(analyticActionTypes.GET_USER_INFO_API_LOADER, false))
            console.log(`method: getUserInfo: exceptionError ${err}`)
            toast.error(ALERT_MESSAGES.SOMETHING_WENT_WRONG)
        }
    }
}

//IS BRAND CATEGORY
export const isbrandCategory = (_params, _cb) => {
    return async (dispatch) => {
        try {
            // dispatch(loader(true))
            const { data } = await axios({
                method: "Get",
                url: apis.IS_BRAND_CATEGORY + _params
            })
            // dispatch(loader(false))
            _cb && _cb(data)
            return
        }
        catch (err) {
            console.log(`method: isbrandCategory: exceptionError ${err}`)
            // dispatch(loader(false))
            toast.error(ALERT_MESSAGES.SOMETHING_WENT_WRONG)
        }
    }
}


//GET USER ANALYTIC
export const getUserAnalytic = (_params, _cb) => {
    return async (dispatch) => {
        try {
            dispatch(loader(analyticActionTypes.USER_ANALYTICS_API_LOADER, true))
            const { data } = await axios({
                method: "Get",
                url: apis.GET_USER_ANALYTICS + _params
            })
            dispatch(loader(analyticActionTypes.USER_ANALYTICS_API_LOADER, false))
            if (data.status && data.code === 200) {
                dispatch({
                    type: analyticActionTypes.SET_USER_ANALYTICS,
                    payload: convertObjectKeysToLowerCase(data.data)
                })
                _cb && _cb()
                return
            }
            return
        }
        catch (err) {
            dispatch(loader(analyticActionTypes.USER_ANALYTICS_API_LOADER, false))
            console.log(`method: getUserAnalytic: exceptionError ${err}`)
            toast.error(ALERT_MESSAGES.SOMETHING_WENT_WRONG)
        }
    }
}

//GET USER JOURNEY
export const getUserJourney = (_params, _cb) => {
    return async (dispatch) => {
        try {
            // dispatch(loader(true))
            const { data } = await axios({
                method: "Get",
                url: apis.GET_USER_JOURNEY + _params,
            })
            // dispatch(loader(false))
            if (data.status && data.code === 200) {
                dispatch({
                    type: analyticActionTypes.SET_USER_JOURNEY,
                    payload: convertKeysToLowerCase(data.data)
                })
                _cb && _cb()
            }
            return
        }
        catch (err) {
            // dispatch(loader(false))
            console.log(`method: getUserJourney: exceptionError ${err}`)
            toast.error(ALERT_MESSAGES.SOMETHING_WENT_WRONG)
        }
    }
}

//GET TOP VISITED MSN
export const getTopVisitedMsns = (_params, _cb) => {
    return async (dispatch) => {
        try {
            dispatch(loader(analyticActionTypes.TOP_VISITED_MSN_API_LOADER, true))
            const { data } = await axios({
                method: "Get",
                url: apis.GET_TOP_VISITED_MSNS + _params,
            })
            dispatch(loader(analyticActionTypes.TOP_VISITED_MSN_API_LOADER, false))
            if (data.status && data.code === 200) {
                dispatch({
                    type: analyticActionTypes.SET_TOP_VISITED_MSNS,
                    payload: convertKeysToLowerCase(data.data)
                })
                _cb && _cb()
            }
            return
        }
        catch (err) {
            dispatch(loader(analyticActionTypes.TOP_VISITED_MSN_API_LOADER, false))
            console.log(`method: getTopVisitedMsns: exceptionError ${err}`)
            toast.error(ALERT_MESSAGES.SOMETHING_WENT_WRONG)
        }
    }
}

//GET ORDER FUNNEL
export const getOrderFunnel = (_params, _cb) => {
    return async (dispatch) => {
        try {
            // dispatch(loader(true))
            const { data } = await axios({
                method: "Get",
                url: apis.GET_ORDER_FUNNEL + _params,
            })
            // dispatch(loader(false))
            if (data.status && data.code === 200) {
                dispatch({
                    type: analyticActionTypes.SET_ORDER_FUNNEL,
                    payload: convertObjectKeysToLowerCase(data.data)
                })
                _cb && _cb()
            }
            return
        }
        catch (err) {
            // dispatch(loader(false))
            console.log(`method: getOrderFunnel: exceptionError ${err}`)
            toast.error(ALERT_MESSAGES.SOMETHING_WENT_WRONG)
        }
    }
}


//GET TOP VISITED CAEGORIES
export const getTopVisitedCategories = (_params, _cb) => {
    return async (dispatch) => {
        try {
            dispatch(loader(analyticActionTypes.USER_TOP_VISITED_CATEGORY_API_LOADER, true))
            const { data } = await axios({
                method: "Get",
                url: apis.GET_TOP_VISITED_CATEGORIES + _params,
            })
            dispatch(loader(analyticActionTypes.USER_TOP_VISITED_CATEGORY_API_LOADER, false))
            if (data.status && data.code === 200) {
                dispatch({
                    type: analyticActionTypes.SET_TOP_VISITED_CATEGORIES,
                    // payload: data.data
                    payload: convertKeysToLowerCase(data.data)
                })
                _cb && _cb()
            }
            return
        }
        catch (err) {
            dispatch(loader(analyticActionTypes.USER_TOP_VISITED_CATEGORY_API_LOADER, false))
            console.log(`method: getTopVisitedCategories: exceptionError ${err}`)
            toast.error(ALERT_MESSAGES.SOMETHING_WENT_WRONG)
        }
    }
}


//GET USER TOP SEARCHES
export const getUserTopSearches = (_params, _cb) => {
    return async (dispatch) => {
        try {
            dispatch(loader(analyticActionTypes.USER_TOP_SEARCH_LOADER, true))
            const { data } = await axios({
                method: "Get",
                url: apis.GET_USER_TOP_SEARCHES + _params,
            })
            dispatch(loader(analyticActionTypes.USER_TOP_SEARCH_LOADER, false))
            if (data.status && data.code === 200) {
                dispatch({
                    type: analyticActionTypes.SET_TOP_USER_SEARCHES,
                    // payload: data.data
                    payload: convertKeysToLowerCase(data.data)
                })
                _cb && _cb()
            }
            return
        }
        catch (err) {
            dispatch(loader(analyticActionTypes.USER_TOP_SEARCH_LOADER, false))
            console.log(`method: getUserTopSearches: exceptionError ${err}`)
            toast.error(ALERT_MESSAGES.SOMETHING_WENT_WRONG)
        }
    }
}

//GET ACQUISITION CHANNEL
export const getAcquisitionChannel = (_params, _cb) => {
    return async (dispatch) => {
        try {
            // dispatch(loader(true))
            const { data } = await axios({
                method: "Get",
                url: apis.GET_ACQUISITION_CHANNEL + _params,
            })
            // dispatch(loader(false))
            if (data.status && data.code === 200) {
                dispatch({
                    type: analyticActionTypes.SET_TOP_ACQUISITION_CHANNEL,
                    payload: convertKeysToLowerCase(data.data)
                })
                _cb && _cb()
            }
            return
        }
        catch (err) {
            // dispatch(loader(false))
            console.log(`method: getAcquisitionChannel: exceptionError ${err}`)
            toast.error(ALERT_MESSAGES.SOMETHING_WENT_WRONG)
        }
    }
}

//GET TRENDING STATES
export const getTrendingStates = (_params, _cb) => {
    return async (dispatch) => {
        try {
            dispatch(loader(analyticActionTypes.TRENDING_STATE_API_LOADER, true))
            const { data } = await axios({
                method: "Get",
                url: apis.GET_TRENDING_STATES + _params,
            })
            dispatch(loader(analyticActionTypes.TRENDING_STATE_API_LOADER, false))
            if (data.status && data.code === 200) {
                dispatch({
                    type: analyticActionTypes.SET_TRENDING_STATE,
                    payload: convertKeysToLowerCase(data.data)
                })
                _cb && _cb()
            }
            return
        }
        catch (err) {
            dispatch(loader(analyticActionTypes.TRENDING_STATE_API_LOADER, false))
            console.log(`method: getTrendingStates: exceptionError ${err}`)
            toast.error(ALERT_MESSAGES.SOMETHING_WENT_WRONG)
        }
    }
}

//GET TOP ACTIVE USERS
export const getTopActiveUsers = (_params, _cb) => {
    return async (dispatch) => {
        try {
            dispatch(loader(analyticActionTypes.TOP_ACTIVE_USER_API_LOADER, true))
            const { data } = await axios({
                method: "Get",
                url: apis.GET_TOP_ACTIVE_USERS + _params,
            })
            dispatch(loader(analyticActionTypes.TOP_ACTIVE_USER_API_LOADER, false))
            if (data.status && data.code === 200) {
                dispatch({
                    type: analyticActionTypes.SET_TOP_ACTIVE_USERS,
                    payload: convertKeysToLowerCase(data.data)
                })
                _cb && _cb()
            }
            return
        }
        catch (err) {
            dispatch(loader(analyticActionTypes.TOP_ACTIVE_USER_API_LOADER, false))
            console.log(`method: getTopActiveUsers: exceptionError ${err}`)
            toast.error(ALERT_MESSAGES.SOMETHING_WENT_WRONG)
        }
    }
}

//GET CATEGORY DETAILS
export const getCategoryDetails = (_params, _cb) => {
    return async (dispatch) => {
        try {
            dispatch(loader(analyticActionTypes.GET_CATEGORY_DETAILS_API_LOADER, true))
            const { data } = await axios({
                method: "Get",
                url: apis.GET_CATEGORY_DETAILS + _params,
            })
            dispatch(loader(analyticActionTypes.GET_CATEGORY_DETAILS_API_LOADER, false))
            if (data.code === 200 && data.message === 'Success') {
                dispatch({
                    type: analyticActionTypes.SET_CATEGORY_DETAILS,
                    payload: convertObjectKeysToLowerCase(data.data)
                })
            }
            return
        }
        catch (err) {
            dispatch(loader(analyticActionTypes.GET_CATEGORY_DETAILS_API_LOADER, false))
            console.log(`method: getCategoryDetails: exceptionError ${err}`)
            toast.error(ALERT_MESSAGES.SOMETHING_WENT_WRONG)
        }
    }
}

//GET TOP PERFORMING CATEGORIES
export const getTopPerformingCategories = (_params, _cb) => {
    return async (dispatch) => {
        try {
            dispatch(loader(analyticActionTypes.TOP_PERFORMING_CATEGORY_API_LOADER, true))
            const { data } = await axios({
                method: "Get",
                url: apis.GET_TOP_PERFORMING_CATEGORIES + _params,
            })
            dispatch(loader(analyticActionTypes.TOP_PERFORMING_CATEGORY_API_LOADER, false))
            if (data.status && data.code === 200) {
                dispatch({
                    type: analyticActionTypes.SET_TOP_PERFORMING_CATEGORIES,
                    payload: convertKeysToLowerCase(data.data)
                })
                _cb && _cb()
            }
            return
        }
        catch (err) {
            dispatch(loader(analyticActionTypes.TOP_PERFORMING_CATEGORY_API_LOADER, false))
            console.log(`method: getTopPerformingCategories: exceptionError ${err}`)
            toast.error(ALERT_MESSAGES.SOMETHING_WENT_WRONG)
        }
    }


}

//GET TOP SELLING BRANDS BY CATEGORY
export const getTopSellingBrandsByCategory = (_params, _cb) => {
    return async (dispatch) => {
        try {
            dispatch(loader(analyticActionTypes.TOP_SELLING_BRANDS_BY_CATEGORY_API_LOADER, true))
            const { data } = await axios({
                method: "Get",
                url: apis.GET_TOP_SELLING_BRANDS_BY_CATEGORY + _params,
            })
            dispatch(loader(analyticActionTypes.TOP_SELLING_BRANDS_BY_CATEGORY_API_LOADER, false))
            if (data.code === 200 && data.message === "Success") {
                dispatch({
                    type: analyticActionTypes.SET_TOP_SELLING_BRANDS_BY_CATEGORY,
                    payload: convertObjectKeysToLowerCase(data.data)
                })
                _cb && _cb()
            }
            return
        }
        catch (err) {
            dispatch(loader(analyticActionTypes.TOP_SELLING_BRANDS_BY_CATEGORY_API_LOADER, false))
            console.log(`method: getTopSellingBrandsByCategory: exceptionError ${err}`)
            toast.error(ALERT_MESSAGES.SOMETHING_WENT_WRONG)
        }
    }
}


//GET TOP SELLING PRODUCTS BY CATEGORY
export const getTopSellingProductsByCategory = (_params, _cb) => {
    return async (dispatch) => {
        try {
            dispatch(loader(analyticActionTypes.TOP_SELLING_PRODUCTS_BY_CATEGORY_API_LOADER, true))
            const { data } = await axios({
                method: "Get",
                url: apis.GET_TOP_SELLING_PRODUCTS_BY_CATEGORY + _params,
            })
            dispatch(loader(analyticActionTypes.TOP_SELLING_PRODUCTS_BY_CATEGORY_API_LOADER, false))
            if (data.code === 200 && data.status) {
                dispatch({
                    type: analyticActionTypes.SET_TOP_SELLING_PRODUCTS_BY_CATEGORY,
                    payload: convertKeysToLowerCase(data.data)
                })
                _cb && _cb()
            }
            return
        }
        catch (err) {
            dispatch(loader(analyticActionTypes.TOP_SELLING_PRODUCTS_BY_CATEGORY_API_LOADER, false))
            console.log(`method: getTopSellingProductsByCategory: exceptionError ${err}`)
            toast.error(ALERT_MESSAGES.SOMETHING_WENT_WRONG)
        }
    }
}

//GET TOP BUYERS BY CATEGORY
export const getTopBuyersByCategory = (_params, _cb) => {
    return async (dispatch) => {
        try {
            dispatch(loader(analyticActionTypes.TOP_BUYERS_BY_CATEGORY_API_LOADER, true))
            const { data } = await axios({
                method: "Get",
                url: apis.GET_TOP_BUYERS_BY_CATEGORY + _params,
            })
            dispatch(loader(analyticActionTypes.TOP_BUYERS_BY_CATEGORY_API_LOADER, false))
            if (data.status && data.code === 200) {
                dispatch({
                    type: analyticActionTypes.SET_TOP_BUYERS_BY_CATEGORY,
                    payload: convertKeysToLowerCase(data.data)
                })
                _cb && _cb()
            }
            return
        }
        catch (err) {
            dispatch(loader(analyticActionTypes.TOP_BUYERS_BY_CATEGORY_API_LOADER, true))
            console.log(`method: getTopBuyersByCategory: exceptionError ${err}`)
            toast.error(ALERT_MESSAGES.SOMETHING_WENT_WRONG)
        }
    }
}

//AVERAGE VISIT MSN BY CATEGORY
export const getAverageVisitMsnByCategory = (_params, _cb) => {
    return async (dispatch) => {
        try {
            // dispatch(loader(analyticActionTypes.TOP_BUYERS_BY_CATEGORY_API_LOADER, true))
            const { data } = await axios({
                method: "Get",
                url: apis.AVERAGE_MSN_VISIT_BY_CATEGORY + _params,
            })
            // dispatch(loader(analyticActionTypes.TOP_BUYERS_BY_CATEGORY_API_LOADER, false))
            if (data.status && data.code === 200) {
                dispatch({
                    type: analyticActionTypes.SET_AVERAGE_VISIT_MSN_BY_CATEGORY,
                    // payload: data.data
                    payload: convertObjectKeysToLowerCase(data.data)
                })
                _cb && _cb()
            }
            return
        }
        catch (err) {
            // dispatch(loader(analyticActionTypes.TOP_BUYERS_BY_CATEGORY_API_LOADER, true))
            console.log(`method: avergateVisitMsnByCategory: exceptionError ${err}`)
            toast.error(ALERT_MESSAGES.SOMETHING_WENT_WRONG)
        }
    }
}


//CHANNEL CATEGORY CONVERSION BY CATEGORY
export const getChannelCategoryConversionByCategory = (_params, _cb) => {
    return async (dispatch) => {
        try {
            // dispatch(loader(analyticActionTypes.TOP_BUYERS_BY_CATEGORY_API_LOADER, true))
            const { data } = await axios({
                method: "Get",
                url: apis.CHANNEL_CATEGORY_CONVERSION_BY_CATEGORY + _params,
            })
            // dispatch(loader(analyticActionTypes.TOP_BUYERS_BY_CATEGORY_API_LOADER, false))
            if (data.status && data.code === 200) {
                dispatch({
                    type: analyticActionTypes.SET_CHANNEL_CATEGORY_CONVERSION_BY_CATEGORY,
                    payload: convertKeysToLowerCase(data.data)
                })
                _cb && _cb()
            }
            return
        }
        catch (err) {
            // dispatch(loader(analyticActionTypes.TOP_BUYERS_BY_CATEGORY_API_LOADER, true))
            console.log(`method: channelCategoryConversionByCategory: exceptionError ${err}`)
            toast.error(ALERT_MESSAGES.SOMETHING_WENT_WRONG)
        }
    }
}

//FETCH OMS MICRO TASK DATA
export const fetchOmsMicrotaskData = (_payload, _cb) => {
    return async (dispatch) => {
        try {
            const { data } = await axios({
                method: "Post",
                url: apis.FETCH_OMS_MICRO_TASK_DATA,
                data: _payload
            })
            _cb(data)
            return
        }
        catch (err) {
            console.log(`method: fetchOmsMicrotaskData: exceptionError ${err}`)
            toast.error(ALERT_MESSAGES.SOMETHING_WENT_WRONG)
        }
    }
}