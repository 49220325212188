import { useSelector } from "react-redux";
import CategoryAnalyticSkeleton from "../Skeleton/CategoryAnalyticSkeleton";
import React from "react";

const CategoryAnalytics = () => {
    const { categoryDetails, categoryDetailsApiLoader } = useSelector((s) => s.analyticStore)
    return (
        <>
            {!categoryDetailsApiLoader ? <div className="flex mb-4 lg:flex-wrap sm:mt-4 sm:flex-wrap sm:mb-2">
                <div className="border border-[#DEDEDE] bg-white px-3 py-2.5 rounded-xl pr-3 mr-3 mb-3">
                    <p className="text-[#0B66F8] text-2xl leading-7 font-bold">{categoryDetails?.categoryvisits}</p>
                    <p className="text-xs text-[#3A3A3A] font-semibold mt-2">Total Visits</p>
                </div>
                <div className="border border-[#DEDEDE] bg-white px-3 py-2.5 rounded-xl pr-3 mr-3 mb-3">
                    <p className="text-[#0B66F8] text-2xl leading-7 font-bold">{categoryDetails?.totalactivemsn}</p>
                    <p className="text-xs text-[#3A3A3A] font-semibold mt-2">Total Active MSN’s</p>
                </div>
                <div className="border border-[#DEDEDE] bg-white px-3 py-2.5 rounded-xl pr-3 mr-3 mb-3">
                    <p className="text-[#0B66F8] text-2xl leading-7 font-bold">{categoryDetails?.totalinstockproducts}</p>
                    <p className="text-xs text-[#3A3A3A] font-semibold mt-2">Total In-Stock Products</p>
                </div>
                <div className="border border-[#DEDEDE] bg-white px-3 py-2.5 rounded-xl pr-3 mr-3 mb-3">
                    <p className="text-[#0B66F8] text-2xl leading-7 font-bold">{categoryDetails?.totaloosproducts}</p>
                    <p className="text-xs text-[#3A3A3A] font-semibold mt-2">Total OOS Products</p>
                </div>
                <div className="border border-[#DEDEDE] bg-white px-3 py-2.5 rounded-xl pr-3 mr-3 mb-3">
                    <p className="text-[#0B66F8] text-2xl leading-7 font-bold">{categoryDetails?.totalorders}</p>
                    <p className="text-xs text-[#3A3A3A] font-semibold mt-2">Total Orders</p>
                </div>
                {/* <div className="border border-[#DEDEDE] bg-white p-2.5 rounded-xl pr-3 mr-3 mb-3">
                    <p className="text-[#0B66F8] text-xl leading-7 font-medium">{categoryDetails?.categoryAvgVisitDuration}</p>
                    <p className="text-xs text-[#3A3A3A] font-medium mt-2">Avg. Visit Duration</p>
                </div> */}
            </div> : <CategoryAnalyticSkeleton />}
        </>
    )
}
export default React.memo(CategoryAnalytics);