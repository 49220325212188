const ACTIVE_TOP_CATEGORY_SKELETION_ROW_SIZE = new Array(7).fill(0)


const TopActiveCategorySkeleton = () => {

    return (
        <div className="w-full">
            {/* <div className="flex items-center justify-between">
                <div className="flex">
                    <h1 className="h-[15px] w-[120px] skeleton-container mr-3">
                        <span className="skeleton-content"></span>
                    </h1>
                    <span className="skeleton-container w-[110px] h-4">
                        <span className="skeleton-content"></span>
                    </span>
                </div>
                <div>
                    <p className="skeleton-container w-[110px] h-4">
                        <span className="skeleton-content"></span>
                    </p>
                </div>
            </div> */}
            {/* <div className="border border-[#DEDEDE] mt-4 bg-white rounded-xl rounded-bl-none rounded-br-none">
                <div className="flex">
                    <div className="w-[34%] text-sm font-medium text-[#3A3A3A] px-4 py-3">
                        <p className="skeleton-container h-4 bg-[#f2f2f2] w-[100%]">
                            <span className="skeleton-content"></span>
                        </p>
                    </div>
                    <div className="w-[15%] text-sm font-medium text-[#3A3A3A] px-4 py-3">
                        <p className="skeleton-container h-4 bg-[#f2f2f2] w-[100%]">
                            <span className="skeleton-content"></span>
                        </p>
                    </div>
                    <div className="w-[18%] text-sm font-medium text-[#3A3A3A] px-4 py-3">
                        <p className="skeleton-container h-4 bg-[#f2f2f2] w-[100%]">
                            <span className="skeleton-content"></span>
                        </p>
                    </div>
                    <div className="w-[18%] text-sm font-medium text-[#3A3A3A] px-4 py-3">
                        <p className="skeleton-container h-4 bg-[#f2f2f2] w-[100%]">
                            <span className="skeleton-content"></span>
                        </p>
                    </div>
                    <div className="w-[15%] text-sm font-medium text-[#3A3A3A] px-4 py-3">
                        <p className="skeleton-container h-4 bg-[#f2f2f2] w-[100%]">
                            <span className="skeleton-content"></span>
                        </p>
                    </div>
                </div>
            </div> */}



            {ACTIVE_TOP_CATEGORY_SKELETION_ROW_SIZE.map((d, index) =>
                <div key={index} className="border border-[#DEDEDE] bg-white rounded-xl rounded-tl-none rounded-tr-none max-h-[436px] overflow-auto">
                    <div className="flex border-t border-[#DEDEDE] first:border-t-0">
                        <div className="w-[34%] text-sm text-[#0881FC] font-medium px-4 py-3 cursor-pointer flex">
                            <div className="w-[54px] h-[54px] min-w-[54px] skeleton-container">
                                <span className="skeleton-content"></span>
                            </div>
                            <div className="pl-4">
                                <p className="w-[80px] h-3 skeleton-container">
                                    <span className="skeleton-content"></span>
                                </p>
                                <p className="w-[80%] h-3 skeleton-container mt-1">
                                    <span className="skeleton-content"></span>
                                </p>
                            </div>
                        </div>
                        <div className="w-[15%] text-sm text-[#3A3A3A] px-4 py-3">
                            <p className="skeleton-container h-3 bg-[#f2f2f2] w-[100%]">
                                <span className="skeleton-content"></span>
                            </p>
                        </div>
                        <div className="w-[18%] text-sm text-[#3A3A3A] px-4 py-3">
                            <p className="skeleton-container h-3 bg-[#f2f2f2] w-[100%]">
                                <span className="skeleton-content"></span>
                            </p>
                        </div>
                        <div className="w-[18%] text-sm text-[#3A3A3A] px-4 py-3">
                            <p className="skeleton-container h-3 bg-[#f2f2f2] w-[100%]">
                                <span className="skeleton-content"></span>
                            </p>
                        </div>
                        <div className="w-[15%] text-sm text-[#3A3A3A] px-4 py-3">
                            <p className="skeleton-container h-3 bg-[#f2f2f2] w-[100%]">
                                <span className="skeleton-content"></span>
                            </p>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default TopActiveCategorySkeleton