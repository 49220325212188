import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import queryString from "query-string"
import { getTrendingStates } from "../../../core/actions/analytic.action"
import moment from "moment"
import React from 'react'
import TrendingStateSkeleton from "../Skeleton/TrendingStateSkeleton"

const TrendingState = ({ searchCriteria }) => {
    const dispatch = useDispatch()
    const { topTrendingState, trendingStateApiLoader } = useSelector((s) => s.analyticStore)


    useEffect(() => {
        const clonedSearchCriteria = { ...searchCriteria }
        clonedSearchCriteria.startDate = moment(clonedSearchCriteria.startDate).format("DD-MM-YYYY")
        clonedSearchCriteria.endDate = moment(clonedSearchCriteria.endDate).format("DD-MM-YYYY")
        const stringifiedSearchCriteria = queryString.stringify(clonedSearchCriteria)
        dispatch(getTrendingStates(stringifiedSearchCriteria, null))
    }, [searchCriteria])

    return (
        <>
            {!trendingStateApiLoader ?
                <div className="mt-5 sm:mt-0">
                    <h1 className="font-bold text-lg leading-6 sm:text-base">Top Trending States</h1>
                    <div className="border border-[#DEDEDE] mt-4 bg-white rounded-xl rounded-bl-none rounded-br-none">
                        <div className="flex items-center px-4 py-3 w-full">
                            <div className="w-[70%] text-sm font-semibold text-[#3A3A3A] pr-5">State</div>
                            <div className="w-[30%] text-sm font-semibold text-[#3A3A3A]">User Searched</div>
                        </div>
                    </div>
                    <div className="border border-[#DEDEDE] bg-white rounded-xl rounded-tl-none rounded-tr-none max-h-[436px] overflow-auto">
                        {topTrendingState?.map((_d, index) =>
                            <div className="flex px-4 pt-2 last:pb-4">
                                <div className="w-[70%] text-sm text-[#3A3A3A] pr-5 ">{_d?.statename}</div>
                                <div className="w-[30%] text-sm text-[#3A3A3A]">{_d?.count} Users</div>
                            </div>
                        )}
                    </div>
                </div> : <TrendingStateSkeleton />}
        </>
    )
}
export default React.memo(TrendingState);