import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserInfo } from "../../core/actions/analytic.action";
import moment from "moment";
import UserProfileSkeleton from "./Skeleton/UserProfileSkeleton";


const UserProfile = ({ userId }) => {
    const { userInfo, getUserInfoApiLoader } = useSelector((s) => s.analyticStore)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getUserInfo(`searchTerm=${userId}`, null))
    }, [userId])

    return (
        <>
            {!getUserInfoApiLoader ? <div className="border border-[#DEDEDE] bg-white p-4 rounded-xl">
                <div className=" flex justify-between pb-5">
                    <p className="text-xs text-[#7E7E7E]">Last vist on: <br></br>{userInfo.lastVisitOn}</p>
                    <p className="text-xs text-[#7E7E7E] text-end">Member since: <br></br>{moment(userInfo.memberSince).format("YYYY")}</p>
                </div>
                <div>
                    <div className="flex items-center justify-center">
                        <div className="w-[144px] h-[142px] rounded-full border border-[#DEDEDE] bg-[#EFEFEF] text-center flex items-center justify-center relative">
                            <img src="/user.png"></img>
                        </div>
                    </div>
                    <p className="text-base text-[#2680EA] font-semibold text-center pt-4">{userInfo?.username}</p>
                    <p className="text-center text-sm text-[#3A3A3A] font-semibold leading-none">ID: {userInfo?.id}</p>
                    <p className="text-sm text-[#3A3A3A] text-center pt-3">{userInfo?.city}, {userInfo?.state}</p>
                    <p className="text-sm text-[#3A3A3A] text-center pt-4 pb-2">Source of Acquisition: {userInfo?.source || "Unknown"}</p>
                </div>
            </div> : <UserProfileSkeleton />}
        </>
    )
}

export default UserProfile;