const UserProfileSkeleton = () => {
    return (
        <div className="border border-[#DEDEDE] bg-white p-4 rounded-xl">
            <div className=" flex justify-between pb-5">
                <p className="skeleton-container h-3 w-[100px]">
                    <span className="skeleton-content"></span>
                </p>
                <p className="skeleton-container h-3 w-[100px]">
                    <span className="skeleton-content"></span>
                </p>
            </div>
            <div>
                <div className="flex items-center justify-center">
                    <div className="w-[144px] h-[142px] skeleton-container user-img rounded-full border border-[#DEDEDE] bg-[#EFEFEF] text-center flex items-center justify-center relative">
                        <span className="skeleton-content"></span>
                    </div>
                </div>
                <p className="mt-4 mx-auto  skeleton-container h-3 w-[100px]">
                    <span className="skeleton-content"></span>
                </p>
                <p className="mt-2 mx-auto  skeleton-container h-3 w-[150px]">
                    <span className="skeleton-content"></span>
                </p>
                <p className="mt-2 mx-auto  skeleton-container h-3 w-[200px]">
                    <span className="skeleton-content"></span>
                </p>
            </div>
        </div>)
}

export default UserProfileSkeleton