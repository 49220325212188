import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTopBuyersByCategory } from "../../../core/actions/analytic.action";
import { DEFAULT_TIME_FRAME, DEFAULT_TIME_FRAME_OPTIONS, SORT_BY, TOP_BUYERS_CATEGORY_TABLE_HEADERS } from "../../../config/constant";
import queryString from "query-string";
import moment from "moment";
import CategoryBuyersSkeleton from "../Skeleton/CategorBuyersSkeleton";
import { useNavigate } from "react-router-dom";
import NoresultFound from "../NoresultFound";

const CategoryBuyers = ({ searchCriteria, setSearchCriteria }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { topBuyers, topBuyersByCategoryApiLoader } = useSelector(s => s.analyticStore)

    const [_searchCriteria, _setSearchCriteria] = useState({})
    const [sortBy, setSortBy] = useState(SORT_BY.ASC)

    useEffect(() => {
        //delete searchCriteria.lastNDays
        _setSearchCriteria({
            ...searchCriteria,
            //lastNDays: DEFAULT_TIME_FRAME
        })
    }, [searchCriteria])

    useEffect(() => {
        if (_searchCriteria.category) {
            const clonedSearchCriteria = { ..._searchCriteria }
            clonedSearchCriteria.startDate = moment(clonedSearchCriteria.startDate).format("DD-MM-YYYY")
            clonedSearchCriteria.endDate = moment(clonedSearchCriteria.endDate).format("DD-MM-YYYY")
            const stringifiedSearchCriteria = queryString.stringify(clonedSearchCriteria)
            dispatch(getTopBuyersByCategory(stringifiedSearchCriteria, null))
        }
    }, [_searchCriteria])


    const handleSorting = (fieldName) => {
        let _sortBy = sortBy === SORT_BY.ASC ? SORT_BY.DESC : SORT_BY.ASC
        setSortBy(_sortBy)
        let clonedSearchCriteria = { ..._searchCriteria }
        clonedSearchCriteria.order = _sortBy
        clonedSearchCriteria.sortingField = fieldName
        const _stringifiedSearchCriteria = queryString.stringify(clonedSearchCriteria)
        dispatch(getTopBuyersByCategory(_stringifiedSearchCriteria, null))
    }


    return (
        <>
            <div className="w-full mt-5">
                <div className="flex items-center justify-between">
                    <h1 className="text-[#3A3A3A] text-lg font-bold sm:text-base">Top Buyers in this Category</h1>
                    <select onChange={(e) => setSearchCriteria({ ..._searchCriteria, lastNDays: e.target.value })} className="w-[110px] py-1.5 px-2.5 border border-[#BFBFBF] rounded-md text-xs bg-white text-[#0B66F8] font-semibold focus:outline-none focus:ring-0 ">
                        {DEFAULT_TIME_FRAME_OPTIONS?.map((_d, index) =>
                            <option selected={_d === DEFAULT_TIME_FRAME} value={_d} key={index}>Last {_d} days</option>
                        )}
                    </select>
                </div>
                <div className="md:visible border border-[#DEDEDE] rounded-xl mt-4 max-h-[436px] sm:overflow-scroll scrollbar-hide overflow-auto">
                    <div className="flex w-full sm:sticky sm:top-0 sm:w-fit sm:z-[1] sticky top-0 bg-white border-b border-b-[#DEDEDE] rounded-tl-xl rounded-tr-xl">
                        <div className="w-1/5 text-sm font-semibold text-[#3A3A3A] px-4 py-3 sm:sticky sm:top-0 sm:w-[100px] sm:min-w-[100px] sm:bg-[#f2f2f2] sm:[&:nth-child(1)]:sticky sm:[&:nth-child(1)]:left-0 sm:[&:nth-child(1)]:bg-[#f2f2f2] sm:[&:nth-child(1)]:z-[1]">User ID</div>
                        <div onClick={() => handleSorting("orderCount")} className="w-[15%] text-sm font-semibold text-[#3A3A3A] px-4 py-3 cursor-pointer sm:sticky sm:top-0 sm:w-[100px] sm:min-w-[100px] sm:bg-[#f2f2f2]">Order Count</div>
                        <div onClick={() => handleSorting("totalPlpViews")} className="w-[18%] text-sm font-semibold text-[#3A3A3A] px-4 py-3 cursor-pointer sm:sticky sm:top-0 sm:w-[100px] sm:min-w-[100px] sm:bg-[#f2f2f2]">Total PLP Views</div>
                        <div onClick={() => handleSorting("totalPdpViews")} className="w-[18%] text-sm font-semibold text-[#3A3A3A] px-4 py-3 cursor-pointer sm:sticky sm:top-0 sm:w-[100px] sm:min-w-[100px] sm:bg-[#f2f2f2]">Total PDP Views</div>
                        <div className="w-[15%] text-sm font-semibold text-[#3A3A3A] px-4 py-3 sm:sticky sm:top-0 sm:w-[100px] sm:min-w-[100px] sm:bg-[#f2f2f2]">Location</div>
                        <div className="w-[14%] text-sm font-semibold text-[#3A3A3A] px-4 py-3 sm:sticky sm:top-0 sm:w-[100px] sm:min-w-[100px] sm:bg-[#f2f2f2]">Referrer</div>
                    </div>
                    {topBuyersByCategoryApiLoader ? <CategoryBuyersSkeleton /> : topBuyers.length === 0 ? <NoresultFound /> :
                        <>
                            {
                                topBuyers?.map((_d, index) =>
                                    <div key={index} className="flex border-t border-[#DEDEDE] first:border-t-0 sm:w-fit bg-white">
                                        <div onClick={() => navigate(`/user-centered-dashboard/${_d?.userid}`)} className="w-1/5 text-sm text-[#0881FC] font-semibold px-4 py-3 sm:w-[100px] sm:min-w-[100px] sm:[&:nth-child(1)]:sticky sm:[&:nth-child(1)]:left-0 sm:[&:nth-child(1)]:bg-white"><span className="cursor-pointer">{_d?.userid}</span></div>
                                        <div className="w-[15%] text-sm text-[#3A3A3A] px-4 py-3 sm:w-[100px] sm:min-w-[100px]">{_d?.ordercount}</div>
                                        <div className="w-[18%] text-sm text-[#3A3A3A] px-4 py-3 sm:w-[100px] sm:min-w-[100px]">{_d?.totalplpviews}</div>
                                        <div className="w-[18%] text-sm text-[#3A3A3A] px-4 py-3 sm:w-[100px] sm:min-w-[100px]">{_d?.totalpdpviews}</div>
                                        <div className="w-[15%] text-sm text-[#3A3A3A] px-4 py-3 sm:w-[100px] sm:min-w-[100px]">{_d?.location}</div>
                                        <div className="w-[14%] text-sm text-[#3A3A3A] px-4 py-3 break-all sm:w-[100px] sm:min-w-[100px]">{_d?.referer}</div>
                                    </div>
                                )
                            }
                        </>
                    }
                </div>
            </div >
        </>
    )
}
export default CategoryBuyers;