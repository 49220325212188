import { useDispatch, useSelector } from "react-redux"
import { DEFAULT_DEVICE, DEFAULT_TIME_FRAME } from "../../../config/constant"
import { useEffect, useState } from "react"
import queryString from "query-string"
import { getTopSellingBrandsByCategory } from "../../../core/actions/analytic.action"
import React from "react"
import BrandCategorySkeleton from "../Skeleton/BrandCategorySkeleton"
import moment from "moment"
import { capitalizeFirstLetter } from "../../../utils/helperFunctions"

const BrandCategory = ({ searchCriteria }) => {
    const dispatch = useDispatch()
    const { topSellingBrands, topSellingBrandsByCategoryApiLoader } = useSelector((s) => s.analyticStore)

    useEffect(() => {
        const clonedSearchCriteria = { ...searchCriteria }
        clonedSearchCriteria.startDate = moment(clonedSearchCriteria.startDate).format("DD-MM-YYYY")
        clonedSearchCriteria.endDate = moment(clonedSearchCriteria.endDate).format("DD-MM-YYYY")
        const stringifiedSearchCriteria = queryString.stringify(clonedSearchCriteria)
        dispatch(getTopSellingBrandsByCategory(stringifiedSearchCriteria, null))
    }, [searchCriteria])

    return (
        <>
            {!topSellingBrandsByCategoryApiLoader ? <div className="mt-5 sm:mt-4">
                <h1 className="font-bold text-lg leading-6 sm:text-base">Top Brands in this Category</h1>
                <div className="border border-[#DEDEDE] mt-4 bg-white rounded-xl rounded-bl-none rounded-br-none">
                    <div className="flex items-center px-4 py-3 w-full">
                        <div className="w-[70%] text-sm font-semibold text-[#3A3A3A] pr-5">Brand</div>
                        <div className="w-[30%] text-sm font-semibold text-[#3A3A3A]">Order Placed</div>
                    </div>
                </div>
                <div className="border border-[#DEDEDE] bg-white rounded-xl rounded-tl-none rounded-tr-none max-h-[158px] overflow-auto">
                    {Object.keys(topSellingBrands).map((_d, index) =>
                        <div key={index} className="flex px-4 pt-2 last:pb-4">
                            <div className="w-[70%] text-sm text-[#3A3A3A] pr-5 ">{_d && capitalizeFirstLetter(_d)}</div>
                            <div className="w-[30%] text-sm text-[#3A3A3A]">{topSellingBrands[_d]}</div>
                        </div>
                    )}
                </div>
            </div> : <BrandCategorySkeleton />}
        </>
    )
}
export default React.memo(BrandCategory) 