import queryString from "query-string";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DEFAULT_TIME_FRAME, DEFAULT_TIME_FRAME_OPTIONS, SORT_BY, TOP_PDP_VISITED_TABLE_HEADERS } from "../../config/constant";
import { getTopVisitedMsns } from "../../core/actions/analytic.action";
import PdpVisitedSkeleton from "./Skeleton/PdpVisitedSkeleton";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import NoresultFound from "./NoresultFound";

const PdpVisited = ({ searchCriteria }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { topVisitedMsns, topVisitedMsnApiLoader } = useSelector(s => s.analyticStore)

    const [_searchCriteria, _setSearchCriteria] = useState({})
    const [sortBy, setSortBy] = useState(SORT_BY.ASC)

    useEffect(() => {
        delete searchCriteria.lastNDays
        _setSearchCriteria({
            ...searchCriteria,
            lastNDays: DEFAULT_TIME_FRAME
        })
    }, [searchCriteria])

    useEffect(() => {
        if (_searchCriteria.userId) {
            const clonedSearchCriteria = { ..._searchCriteria }
            clonedSearchCriteria.startDate = moment(clonedSearchCriteria.startDate).format("DD-MM-YYYY")
            clonedSearchCriteria.endDate = moment(clonedSearchCriteria.endDate).format("DD-MM-YYYY")
            const stringifiedSearchCriteria = queryString.stringify(clonedSearchCriteria)
            dispatch(getTopVisitedMsns(stringifiedSearchCriteria, null))
        }
    }, [_searchCriteria])

    const handleSorting = (fieldName) => {
        let _sortBy = sortBy === SORT_BY.ASC ? SORT_BY.DESC : SORT_BY.ASC
        setSortBy(_sortBy)
        let clonedSearchCriteria = { ..._searchCriteria }
        clonedSearchCriteria.order = _sortBy
        clonedSearchCriteria.sortingField = fieldName
        const _stringifiedSearchCriteria = queryString.stringify(clonedSearchCriteria)
        dispatch(getTopVisitedMsns(_stringifiedSearchCriteria, null))
    }


    return (
        <>
            <div className="w-full sm:mt-4">
                <div className="flex items-center justify-between">
                    <h1 className="text-[#3A3A3A] text-lg font-bold sm:text-base">Top 5 PDP Visited</h1>
                    <select onChange={(e) => _setSearchCriteria({ ..._searchCriteria, lastNDays: e.target.value })} className="w-[110px] py-1.5 px-2.5 border border-[#BFBFBF] rounded-md text-xs bg-white text-[#0B66F8] font-semibold focus:outline-none focus:ring-0 cursor-pointer">
                        {DEFAULT_TIME_FRAME_OPTIONS?.map((_d, index) =>
                            <option selected={_d === DEFAULT_TIME_FRAME} value={_d} key={index}>Last {_d} days</option>
                        )}
                    </select>
                </div>
                <div className="md:visible border border-[#DEDEDE] rounded-xl mt-4 max-h-[436px] sm:overflow-scroll scrollbar-hide overflow-auto">
                    <div className="flex w-full sm:sticky sm:top-0 sm:w-fit sm:z-[1] sticky top-0 bg-white border-b border-b-[#DEDEDE] rounded-tl-xl rounded-tr-xl">
                        <div className="w-3/5 text-sm font-semibold text-[#3A3A3A] px-4 py-3 sm:sticky sm:top-0 sm:w-[200px] sm:min-w-[200px] sm:bg-[#f2f2f2] sm:[&:nth-child(1)]:sticky sm:[&:nth-child(1)]:left-0 sm:[&:nth-child(1)]:bg-[#f2f2f2] sm:[&:nth-child(1)]:z-[1]">Product Details</div>
                        <div onClick={() => handleSorting("viewcount")} className="w-1/5 text-sm font-semibold text-[#3A3A3A] px-4 py-3 cursor-pointer sm:sticky sm:top-0 sm:w-[100px] sm:min-w-[100px] sm:bg-[#f2f2f2]">View Count</div>
                        <div onClick={() => handleSorting("ordercount")} className="w-1/5 text-sm font-semibold text-[#3A3A3A] px-4 py-3 cursor-pointer sm:sticky sm:top-0 sm:w-[100px] sm:min-w-[100px] sm:bg-[#f2f2f2]">Order Count</div>
                    </div>
                    {topVisitedMsnApiLoader ? <PdpVisitedSkeleton /> : topVisitedMsns.length === 0 ? <NoresultFound /> :
                        <>
                            {topVisitedMsns?.map((_d, index) =>
                                <div key={index} className="flex border-t border-[#DEDEDE] first:border-t-0 sm:w-fit bg-white">
                                    <div className="w-3/5 flex px-4 py-3 sm:w-[200px] sm:min-w-[200px] sm:[&:nth-child(1)]:sticky sm:[&:nth-child(1)]:left-0 sm:[&:nth-child(1)]:bg-white">
                                        <img src={_d.productimage} className="w-[54px] h-[54px] border border-[#DEDEDE] rounded-[10px]" />
                                        <div className="pl-4">
                                            <a onClick={() => navigate(`/category-centered-dashboard/${_d.categoryid}`)} target="_blank" rel="noopener noreferrer" className="text-[#0881FC] text-xs cursor-pointer">{_d.categoryname}</a><br></br>
                                            <a target="_blank" rel="noopener noreferrer" href={_d.producturl} className="text-[#3A3A3A] text-sm mt-1 sm:line-clamp-2">
                                                {_d.productname}
                                            </a>
                                        </div>
                                    </div>
                                    <div className="w-1/5 text-sm text[#3A3A3A] px-4 py-3 sm:w-[100px] sm:min-w-[100px]">{_d.viewcount}</div>
                                    <div className="w-1/5 text-sm text[#3A3A3A] px-4 py-3 sm:w-[100px] sm:min-w-[100px]">{_d.ordercount || 0}</div>
                                </div>
                            )}
                        </>
                    }
                </div>
            </div>
        </>
    )
}
export default PdpVisited;