import React from "react"

const BrandCategorySkeleton = () => {
    return (<div className="mt-5">
        <h1 className="h-[15px] w-[60%] skeleton-container bg-[gray]">
            <span className="skeleton-content"></span>
        </h1>
        <div className="border border-[#DEDEDE] mt-4 bg-white rounded-xl rounded-bl-none rounded-br-none">
            <div className="flex items-center px-4 py-3 w-full">
                <div className="w-[70%]  text-sm font-medium pr-5 ">
                    <p className="skeleton-container h-4 bg-[#f2f2f2] w-[70%]">
                        <span className="skeleton-content"></span>
                    </p>
                </div>
                <div className="w-[30%] text-sm font-medium text-[#3A3A3A]">
                    <p className="skeleton-container h-4 bg-[#f2f2f2] w-[80%]">
                        <span className="skeleton-content"></span>
                    </p>
                </div>
            </div>
        </div>
        <div className="border border-[#DEDEDE] bg-white rounded-xl rounded-tl-none rounded-tr-none max-h-[436px] overflow-auto">
            <div className="flex px-4 pt-3 last:pb-4">
                <div className="w-[70%] pr-5 ">
                    <p className="skeleton-container h-3 bg-[#f2f2f2] w-[60%]">
                        <span className="skeleton-content"></span>
                    </p>
                </div>
                <div className="w-[30%]">
                    <p className="skeleton-container h-3 bg-[#f2f2f2] w-[70%]">
                        <span className="skeleton-content"></span>
                    </p>
                </div>
            </div>
            <div className="flex px-4 pt-3 last:pb-4">
                <div className="w-[70%] pr-5 ">
                    <p className="skeleton-container h-3 bg-[#f2f2f2] w-[60%]">
                        <span className="skeleton-content"></span>
                    </p>
                </div>
                <div className="w-[30%]">
                    <p className="skeleton-container h-3 bg-[#f2f2f2] w-[70%]">
                        <span className="skeleton-content"></span>
                    </p>
                </div>
            </div>
            <div className="flex px-4 pt-3 last:pb-4">
                <div className="w-[70%] pr-5 ">
                    <p className="skeleton-container h-3 bg-[#f2f2f2] w-[60%]">
                        <span className="skeleton-content"></span>
                    </p>
                </div>
                <div className="w-[30%]">
                    <p className="skeleton-container h-3 bg-[#f2f2f2] w-[70%]">
                        <span className="skeleton-content"></span>
                    </p>
                </div>
            </div>
        </div>
    </div>)
}

export default BrandCategorySkeleton 