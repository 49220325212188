import queryString from "query-string";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DEFAULT_COUNT, DEFAULT_DEVICE, DEFAULT_TIME_FRAME, DEFAULT_TIME_FRAME_OPTIONS, DEFAULT_ACTIVE_USER_COUNT, DEFAULT_TOP_CATEGORIES_LAST_N_DAY_COUNT, DEFAULT_TOP_CATEGORIES_COUNT, SORT_BY, TOP_CATEGORY_TABLE_HEADERS } from "../../../config/constant";
import { getTopPerformingCategories } from "../../../core/actions/analytic.action";
import { useNavigate } from "react-router-dom";
import TopActiveCategorySkeleton from "../Skeleton/TopActiveCategorySkeleton";
import { formatRevenue } from "../../../utils/helperFunctions";
import NoresultFound from "../NoresultFound";

const TopPerformingCategories = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { topPerformingCategories, topPerformingCategoryApiLoader } = useSelector(s => s.analyticStore)

    const [searchCriteria, setSearchCriteria] = useState({
        lastNDays: DEFAULT_TOP_CATEGORIES_LAST_N_DAY_COUNT,
        device: DEFAULT_DEVICE,
        count: DEFAULT_TOP_CATEGORIES_COUNT
    })
    const [sortBy, setSortBy] = useState(SORT_BY.ASC)

    useEffect(() => {
        const stringifiedSearchCriteria = queryString.stringify(searchCriteria)
        dispatch(getTopPerformingCategories(stringifiedSearchCriteria, null))
    }, [searchCriteria])

    const handleSorting = (fieldName) => {
        let _sortBy = sortBy === SORT_BY.ASC ? SORT_BY.DESC : SORT_BY.ASC
        setSortBy(_sortBy)
        let clonedSearchCriteria = { ...searchCriteria }
        clonedSearchCriteria.order = _sortBy
        clonedSearchCriteria.sortingField = fieldName
        const _stringifiedSearchCriteria = queryString.stringify(clonedSearchCriteria)
        dispatch(getTopPerformingCategories(_stringifiedSearchCriteria, null))
    }

    return (
        <>
            <div className="w-full">
                <div className="flex items-center justify-between">
                    <div className="flex items-center sm:mr-3">
                        <h1 className="font-bold text-lg leading-6 mr-3 sm:text-base sm:mr-2.5">Top Performing Categories</h1>
                        <select onChange={(e) => setSearchCriteria({ ...searchCriteria, count: e.target.value })} className="w-[142px] py-1.5 px-2.5 border border-[#BFBFBF] rounded-md text-xs bg-white text-[#0B66F8] font-semibold cursor-pointer focus:outline-none focus:ring-0 sm:hidden">
                            {DEFAULT_ACTIVE_USER_COUNT?.map((_d, index) =>
                                <option selected={_d === DEFAULT_TOP_CATEGORIES_COUNT} value={_d} key={index}>Top {_d} Categories</option>
                            )}
                        </select>
                        <select onChange={(e) => setSearchCriteria({ ...searchCriteria, count: e.target.value })} className="w-[60px] h-[30px] py-1.5 px-2.5 border border-[#BFBFBF] rounded-md text-xs bg-white text-[#0B66F8] font-semibold cursor-pointer focus:outline-none focus:ring-0 md:hidden">
                            {DEFAULT_ACTIVE_USER_COUNT?.map((_d, index) =>
                                <option selected={_d === DEFAULT_TOP_CATEGORIES_COUNT} value={_d} key={index}>{_d}</option>
                            )}
                        </select>
                    </div>
                    <div>
                        <select onChange={(e) => setSearchCriteria({ ...searchCriteria, lastNDays: e.target.value })} className="w-[110px] py-1.5 px-2.5 border border-[#BFBFBF] rounded-md text-xs bg-white text-[#0B66F8] font-semibold cursor-pointer focus:outline-none focus:ring-0">
                            {DEFAULT_TIME_FRAME_OPTIONS?.map((_d, index) =>
                                <option selected={_d === DEFAULT_TOP_CATEGORIES_LAST_N_DAY_COUNT} value={_d} key={index}>Last {_d} days</option>
                            )}
                        </select>
                    </div>
                </div>
                <div className="md:visible border border-[#DEDEDE] rounded-xl mt-4 max-h-[436px] sm:overflow-scroll scrollbar-hide overflow-auto">
                    {/* <div className="border border-[#DEDEDE] mt-4 bg-white rounded-xl rounded-bl-none rounded-br-none"> */}
                    <div className="flex w-full sm:sticky sm:top-0 sm:w-fit sm:z-[1] sticky top-0 bg-white border-b border-b-[#DEDEDE] rounded-tl-xl rounded-tr-xl">
                        <div className="w-[40%] text-sm font-semibold text-[#3A3A3A] px-4 py-3 sm:sticky sm:top-0 sm:w-[170px] sm:min-w-[170px] sm:bg-[#f2f2f2] sm:[&:nth-child(1)]:sticky sm:[&:nth-child(1)]:left-0 sm:[&:nth-child(1)]:bg-[#f2f2f2] sm:[&:nth-child(1)]:z-[1]">Category</div>
                        <div onClick={() => handleSorting("totalVisits")} className="w-[20%] text-sm font-semibold text-[#3A3A3A] px-4 py-3 cursor-pointer sm:sticky sm:top-0 sm:w-[100px] sm:min-w-[100px] sm:bg-[#f2f2f2]">Total Visits</div>
                        {/* <div className="w-[18%] text-sm font-medium text-[#3A3A3A] px-4 py-3">Avg Visit Duration</div> */}
                        <div onClick={() => handleSorting("orderCount")} className="w-[20%] text-sm font-semibold text-[#3A3A3A] px-4 py-3 cursor-pointer sm:sticky sm:top-0 sm:w-[100px] sm:min-w-[100px] sm:bg-[#f2f2f2]">Total Order</div>
                        <div className="w-[20%] text-sm font-semibold text-[#3A3A3A] px-4 py-3 sm:sticky sm:top-0 sm:w-[120px] sm:min-w-[120px] sm:bg-[#f2f2f2]">Revenue Generated (&#8377;)</div>
                    </div>
                    {/* </div> */}
                    {/* <div className="border border-[#DEDEDE] bg-white rounded-xl rounded-tl-none rounded-tr-none max-h-[436px] overflow-auto scrollbar-hide"> */}

                    {!topPerformingCategoryApiLoader ? (
                        topPerformingCategories?.length > 0 ? (
                            topPerformingCategories?.map((_d, index) => (
                                <div key={index} className="flex border-t border-[#DEDEDE] first:border-t-0 sm:w-fit">
                                    <div className="w-[40%] text-sm text-[#0881FC] font-medium px-4 py-3 flex sm:w-[170px] sm:min-w-[170px] sm:[&:nth-child(1)]:sticky sm:[&:nth-child(1)]:left-0 sm:[&:nth-child(1)]:bg-white">
                                        <div className="w-[50px] h-[50px] min-w-[50px] border border-[#DEDEDE] rounded-[10px]">
                                            <img src={_d?.imageurl} className="rounded-[10px]" />

                                        </div>
                                        <div className="pl-4 ">
                                            <p className="text-[#3A3A3A] text-sm mt-1">{_d?.name}</p>
                                            <a onClick={() => navigate(`/category-centered-dashboard/${_d?.id}`)} src={_d?.imageurl} target="_blank" rel="noopener noreferrer" className="text-[#0881FC] text-xs font-semibold cursor-pointer">{_d?.id}</a>
                                        </div>
                                    </div>
                                    <div className="w-[20%] text-sm text-[#3A3A3A] px-4 py-3 sm:w-[100px] sm:min-w-[100px]">{_d?.totalvisits}</div>
                                    {/* <div className="w-[18%] text-sm text-[#3A3A3A] px-4 py-3">{_d?.avgVisitDuration}</div> */}
                                    <div className="w-[20%] text-sm text-[#3A3A3A] px-4 py-3 sm:w-[100px] sm:min-w-[100px]">{_d?.ordercount}</div>
                                    <div className="w-[20%] text-sm text-[#3A3A3A] px-4 py-3 sm:w-[120px] sm:min-w-[120px]">{formatRevenue(_d?.revenuegenerated || 0)}</div>
                                </div>
                            )
                            )
                        )
                            :
                            (
                                <NoresultFound />
                            )
                    ) :
                        (
                            <TopActiveCategorySkeleton />
                        )}
                    {/* </div> */}
                </div>

                {/* <div className="relative mt-5 sm:visible md:hidden">
                    <div className="overflow-x-auto overflow-y-visible">
                        <table className="w-full table-fixed">
                            {TOP_CATEGORY_TABLE_HEADERS.map((_headerObj, index) =>
                                <tr className="even:bg-[#ffffff] odd:bg-[#f3f3f3] header-data">
                                    <td className="sticky left-0 z-20 w-[140px] text-sm font-semibold text-[#3A3A3A] py-3 px-4 even:bg-[#ffffff] odd:bg-[#f3f3f3]">{_headerObj["key"]}</td>
                                    {topPerformingCategories?.map((_d, index) =>
                                        <td className="w-[185px] text-xs text-[#3A3A3A] px-4 py-3 break-all">
                                            {(_headerObj['val1'] === 'id') ? (
                                                <a onClick={() => navigate(`/category-centered-dashboard/${_d[_headerObj['val1']]}`)} className="text-[#0881FC] font-medium cursor-pointer flex items-center">
                                                    <div className="w-[50px] h-[50px] min-w-[50px] border border-[#DEDEDE] rounded-[10px]">
                                                        <img src={_d[_headerObj['val3']]} className="rounded-[10px]" />
                                                    </div>
                                                    <div className="pl-3">
                                                        <p className="text-[#3A3A3A] text-sm line-clamp-1">{_d[_headerObj['val2']]}</p>
                                                        <p className="mt-1 text-xs">{_d[_headerObj['val1']]}</p>
                                                    </div>
                                                </a>
                                            ) : (
                                                _d[_headerObj['val']]

                                            )}
                                        </td>
                                    )}
                                </tr>
                            )}
                        </table>
                    </div>
                </div> */}

            </div>
        </>
    )
}
export default TopPerformingCategories;