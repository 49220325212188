import FundsFillIcon from "remixicon-react/FundsFillIcon";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { DEFAULT_DEVICE, DEFAULT_LAST_N_DAY } from "../../../config/constant";
import queryString from "query-string";
import { getAverageVisitMsnByCategory } from "../../../core/actions/analytic.action";
import { getCurrentDate, getCurrentDateMinusNDay } from "../../../utils/helperFunctions";

const TrendInsights = () => {
    const dispatch = useDispatch()

    const { averageVisitMsnByCategory } = useSelector(s => s.analyticStore)

    const [searchCriteria, setSearchCriteria] = useState({
        lastNDays: DEFAULT_LAST_N_DAY,
        device: DEFAULT_DEVICE,
        category: 230130000
    })

    useEffect(() => {
        const stringifiedSearchCriteria = queryString.stringify(searchCriteria)
        dispatch(getAverageVisitMsnByCategory(stringifiedSearchCriteria, null))
    }, [searchCriteria])

    return (
        <>
            <div className="mt-7">
                <h1 className="font-bold text-lg leading-6">Trend Insights</h1>
                <div className="flex mt-4 overflow-x-auto">
                    <div className="border border-[#DEDEDE] p-4 w-[342px] min-w-[342px] rounded-2xl mr-5 last:mr-0">
                        <div className="flex">
                            <div>
                                <FundsFillIcon size={26} className="bg-[#0881FC] text-white w-[20px] h-5 rounded-full" />
                            </div>
                            <div className='text-[#3A3A3A] pl-4'>
                                <p className='text-sm font-semibold  leading-4'>On an average users visit {parseInt(averageVisitMsnByCategory?.["all category"] || 0)} pages before placing an order</p>
                                <p className='text-xs mt-2'>Duration: {getCurrentDateMinusNDay(7)} - {getCurrentDate()}</p>
                            </div>
                        </div>
                    </div>
                    <div className="border border-[#DEDEDE] p-4 w-[342px] min-w-[342px] rounded-2xl mr-5 last:mr-0">
                        <div className="flex">
                            <div>
                                <FundsFillIcon size={26} className="bg-[#0881FC] text-white w-[20px] h-5 rounded-full" />
                            </div>
                            <div className='text-[#3A3A3A] pl-4'>
                                <p className='text-sm font-semibold  leading-4'>Average page load time for moglix is {averageVisitMsnByCategory?.["avgpageloadtime"] || 0} sec</p>
                                <p className='text-xs mt-2'>Duration: {getCurrentDateMinusNDay(7)} - {getCurrentDate()}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* skeleton */}

            {/* <div className="mt-7">
                <h1 className="h-[15px] w-[200px] skeleton-container">
                    <span className="skeleton-content"></span>
                </h1>
                <div className="flex mt-4 overflow-x-auto">
                    <div className="border border-[#DEDEDE] p-4 w-[342px] min-w-[342px] rounded-2xl mr-5 last:mr-0">
                        <div className="flex">
                            <div className='skeleton-container w-6 h-5 rounded-full bg-[#f2f2f2]'>
                                <span className="skeleton-content"></span>
                            </div>
                            <div className='text-[#3A3A3A] pl-4 w-full'>
                                <p className='text-sm font-medium  leading-4 skeleton-container h-3 w-full'>
                                    <span className="skeleton-content"></span>
                                </p>
                                <p className='text-sm font-medium  mt-2 leading-4 skeleton-container h-3 w-[80%]'>
                                    <span className="skeleton-content"></span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="border border-[#DEDEDE] p-4 w-[342px] min-w-[342px] rounded-2xl mr-5 last:mr-0">
                        <div className="flex">
                            <div className='skeleton-container w-6 h-5 rounded-full bg-[#f2f2f2]'>
                                <span className="skeleton-content"></span>
                            </div>
                            <div className='text-[#3A3A3A] pl-4 w-full'>
                                <p className='text-sm font-medium  leading-4 skeleton-container h-3 w-full'>
                                    <span className="skeleton-content"></span>
                                </p>
                                <p className='text-sm font-medium  mt-2 leading-4 skeleton-container h-3 w-[80%]'>
                                    <span className="skeleton-content"></span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="border border-[#DEDEDE] p-4 w-[342px] min-w-[342px] rounded-2xl mr-5 last:mr-0">
                        <div className="flex">
                            <div className='skeleton-container w-6 h-5 rounded-full bg-[#f2f2f2]'>
                                <span className="skeleton-content"></span>
                            </div>
                            <div className='text-[#3A3A3A] pl-4 w-full'>
                                <p className='text-sm font-medium  leading-4 skeleton-container h-3 w-full'>
                                    <span className="skeleton-content"></span>
                                </p>
                                <p className='text-sm font-medium  mt-2 leading-4 skeleton-container h-3 w-[80%]'>
                                    <span className="skeleton-content"></span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="border border-[#DEDEDE] p-4 w-[342px] min-w-[342px] rounded-2xl mr-5 last:mr-0">
                        <div className="flex">
                            <div className='skeleton-container w-6 h-5 rounded-full bg-[#f2f2f2]'>
                                <span className="skeleton-content"></span>
                            </div>
                            <div className='text-[#3A3A3A] pl-4 w-full'>
                                <p className='text-sm font-medium  leading-4 skeleton-container h-3 w-full'>
                                    <span className="skeleton-content"></span>
                                </p>
                                <p className='text-sm font-medium  mt-2 leading-4 skeleton-container h-3 w-[80%]'>
                                    <span className="skeleton-content"></span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    )
}
export default TrendInsights;