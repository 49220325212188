import { ALERT_MESSAGES, USER_TOKEN_KEY } from "../../config/constant";
import toast from "react-hot-toast";
import { jwtDecode } from "jwt-decode";
import authActionTypes from "../actionTypes/auth.actionType";

const {
    SET_USER_LOADER,
    SET_USER,
    SET_USER_PROFILE,
    SET_IS_AUTHENTICATED,
    SET_LOCAL_STORAGE,
} = authActionTypes;


const setUserloader = (data) => {
    return {
        type: SET_USER_LOADER,
        payload: data,
    };
};

export const setUser = (data) => {
    return {
        type: SET_USER,
        payload: data,
    };
};

export const setUserProfile = (data) => {
    return {
        type: SET_USER_PROFILE,
        payload: data,
    };
};

export const setIsAuthenticated = (data) => {
    return {
        type: SET_IS_AUTHENTICATED,
        payload: data,
    };
};

export const setLocalStorage = (data) => {
    return {
        type: SET_LOCAL_STORAGE,
        payload: data,
    };
};


export const loginWithMicrosoft = (_data, _callback) => {
    return (dispatch) => {
        try {
            const decodedData = jwtDecode(_data);
            window.localStorage.setItem(USER_TOKEN_KEY, _data)
            dispatch(setIsAuthenticated(true))
            dispatch(setUser(decodedData))
            if (_callback) {
                _callback()
            }
        }
        catch (err) {
            toast.error(ALERT_MESSAGES.OOPS_LOGIN_FAILED)
            console.log(err)
            console.log(`method: loginWithMicrosoft: exceptionError: ${err.message}`)
        }
    }
}


export const logout = (_callback) => {
    return (dispatch) => {
        try {
            window.localStorage.removeItem(USER_TOKEN_KEY)
            dispatch(setIsAuthenticated(false))
            dispatch(setUser({}))
            if (_callback) {
                _callback()
            }
        }
        catch (err) {
            toast.error(ALERT_MESSAGES.OOPS_LOGOUT_FAILED)
            console.log(err)
            console.log(`method: logout: exceptionError: ${err.message}`)
        }
    }
}