import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserAnalytic } from "../../core/actions/analytic.action";
import queryString from "query-string";
import DashboardAnalyticSkeleton from "./Skeleton/DashboardAnalyticSkeleton";
import moment from "moment";
import { formatRevenue } from "../../utils/helperFunctions";


const DashboardAnalytics = ({ searchCriteria }) => {
    const dispatch = useDispatch()
    const { userAnalytic, userAnalyticApiLoader } = useSelector(s => s.analyticStore)

    useEffect(() => {
        // update startDate and endDate to DD-MM-YYYY
        const clonedSearchCriteria = { ...searchCriteria }
        clonedSearchCriteria.startDate = moment(clonedSearchCriteria.startDate).format("DD-MM-YYYY")
        clonedSearchCriteria.endDate = moment(clonedSearchCriteria.endDate).format("DD-MM-YYYY")

        const stringifiedSearchCriteria = queryString.stringify(clonedSearchCriteria)
        dispatch(getUserAnalytic(stringifiedSearchCriteria, null))
    }, [searchCriteria])

    return (
        <>
            {!userAnalyticApiLoader ? <div className="flex mb-4 lg:flex-wrap  sm:flex-wrap sm:mt-4">
                <div className="border border-[#DEDEDE] bg-white px-3 py-2.5 rounded-xl pr-3 mr-3 mb-3">
                    <p className="text-[#0B66F8] text-2xl leading-7 font-bold sm:text-base">{userAnalytic?.totalsession}</p>
                    <p className="text-xs text-[#3A3A3A] font-semibold mt-2">Total Session</p>
                </div>
                <div className="border border-[#DEDEDE] bg-white px-3 py-2.5 rounded-xl pr-3 mr-3 mb-3">
                    <p className="text-[#0B66F8] text-2xl leading-7 font-bold sm:text-base">{userAnalytic?.pagepersession}</p>
                    <p className="text-xs text-[#3A3A3A] font-semibold mt-2">Pages/Session</p>
                </div>
                <div className="border border-[#DEDEDE] bg-white px-3 py-2.5 rounded-xl pr-3 mr-3 mb-3">
                    <p className="text-[#0B66F8] text-2xl leading-7 font-bold sm:text-base">{userAnalytic?.avgvisitduration}</p>
                    <p className="text-xs text-[#3A3A3A] font-semibold mt-2">Avg. Visit Duration</p>
                </div>
                <div className="border border-[#DEDEDE] bg-white px-3 py-2.5 rounded-xl pr-3 mr-3 mb-3">
                    <p className="text-[#0B66F8] text-2xl leading-7 font-bold sm:text-base">{userAnalytic?.ordercount}</p>
                    <p className="text-xs text-[#3A3A3A] font-semibold mt-2">Total Order Count</p>
                </div>
                <div className="border border-[#DEDEDE] bg-white px-3 py-2.5 rounded-xl pr-3 mr-3 mb-3">
                    <p className="text-[#0B66F8] text-2xl leading-7 font-bold sm:text-base">{formatRevenue(userAnalytic?.revenuegenerated || 0)}</p>
                    <p className="text-xs text-[#3A3A3A] font-semibold mt-2">Revenue Generated</p>
                </div>
                <div className="border border-[#DEDEDE] bg-white px-3 py-2.5 rounded-xl pr-3 mr-3 mb-3">
                    <p className="text-[#0B66F8] text-2xl leading-7 font-bold sm:text-base">{formatRevenue(userAnalytic?.avgordervalue || 0)}</p>
                    <p className="text-xs text-[#3A3A3A] font-semibold mt-2">Avg. Order Value</p>
                </div>
                <div className="border border-[#DEDEDE] bg-white px-3 py-2.5 rounded-xl pr-3 mr-3 mb-3">
                    <p className="text-[#0B66F8] text-2xl leading-7 font-bold sm:text-base">{userAnalytic?.rto} %</p>
                    <p className="text-xs text-[#3A3A3A] font-semibold mt-2">RTO</p>
                </div>
                {userAnalytic?.avgPageLoadTime > 0 && <div className="border border-[#DEDEDE] bg-white px-3 py-2.5 rounded-xl pr-3 mr-3 mb-3">
                    <p className="text-[#0B66F8] text-2xl leading-7 font-bold sm:text-base">{userAnalytic?.avgpageloadtime} sec</p>
                    <p className="text-xs text-[#3A3A3A] font-semibold mt-2">Page Load Time</p>
                </div>}

            </div> : <DashboardAnalyticSkeleton />}
        </>
    )
}
export default DashboardAnalytics;