import { useSelector } from "react-redux";
import CategoryDetailsBlockSkeleton from "../Skeleton/CategoryDetailsBlockSkeleton";

const CategoryBlock = () => {
    const { categoryDetails, categoryDetailsApiLoader } = useSelector((s) => s.analyticStore)
    return (
        <>
            {!categoryDetailsApiLoader ? <div className="w-full mt-5 border border-[#DEDEDE] bg-white p-10 rounded-xl min-h-[296px]">
                <div className="flex items-center justify-center">
                    <img src={`https://cdn.moglix.com/${categoryDetails?.mainimagelink}`} className="w-[144px] h-[142px]  border border-[#DEDEDE] rounded-xl "></img>
                </div>
                <a href={`https://www.moglix.com/${categoryDetails?.categorylink}`} target="_blank"
                    rel="noopener noreferrer" className="text-base text-[#2680EA] font-semibold text-center pt-6 leading-5 block">
                    {categoryDetails?.categoryname}
                </a>
                {/* <p className="text-center text-sm text-[#3A3A3A] font-medium mt-2">{categoryDetails?.searchCategoryDetailsOptim?.categoryLink}</p> */}
            </div> : <CategoryDetailsBlockSkeleton />}
        </>
    )
}
export default CategoryBlock;