import moment from "moment";

export const identifyString = (input) => {
    const isEmail = (str) => {
        return str.includes('@');
    };
    const isPhoneNumber = (str) => {
        return /^\d{10}$/.test(str);
    };
    const isUserId = (str) => {
        return /^\d+$/.test(str) && str.length <= 8;
    };
    if (isEmail(input)) {
        return 'EMAIL';
    } else if (isPhoneNumber(input)) {
        return 'PHONE_NUMBER';
    }
    else if (isUserId(input)) {
        return 'USER_ID'
    }
    else {
        return 'CATEGORY_NAME';
    }
};

export const platformMapper = (platform) => {
    if (platform?.includes('facebook')) {
        return 'Facebook';
    } else if (platform?.includes('instagram')) {
        return 'Instagram';
    } else if (platform?.includes('youtube')) {
        return 'YouTube';
    } else if (platform?.includes('google')) {
        return 'Google';
    }
    else if (platform?.includes('criteo')) {
        return 'Criteo';
    }
    else if (platform?.includes('moglix')) {
        return 'Moglix';
    }
    else {
        return 'Unknown';
    }
};


export const journeyExplorerPageMapper = (str) => {
    if (str) {
        let words = str.split('_');
        let capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
        return capitalizedWords;
    }
}

export const formatRevenue = (number) => {
    return new Intl.NumberFormat('en-IN', {
        style: 'currency',
        currency: 'INR'
    }).format(number);
}

export const downloadCSV = (csvContent, filename) => {
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

export const removeSpecialCharacters = (inputString) => {
    // Use a regular expression to replace special characters with an empty string
    return inputString.replace(/[^\w\s]/gi, '');
}

export const convertToCSV = (data) => {
    data.map((r) => r.productlist = r.productlist.map((d) => d.msn))
    const headers = Object.keys(data[0]);
    let csvContent = headers.join(',') + '\n';
    data.forEach((item) => {
        const values = headers.map((header) => {
            const fieldValue = item[header];
            return typeof fieldValue === 'string' ? `"${fieldValue}"` : fieldValue;
        });
        csvContent += values.join(',') + '\n';
    });
    return csvContent;
}

export const covertTextToJson = (textData) => {
    const lines = textData.split('\n');
    const headers = lines[0].split(',');
    let jsonData = [];
    for (let i = 1; i < lines.length; i++) {
        const values = lines[i].split(',');
        const obj = {};
        for (let j = 0; j < headers.length; j++) {
            obj[headers[j].trim()] = isNaN(values[j]) ? values[j]?.trim() : parseFloat(values[j]);
        }
        jsonData.push(obj);
    }
    if (jsonData.length) {
        jsonData.pop()
    }
    return jsonData
}

export const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const getCurrentDate = () => {
    return moment().format('YYYY-MM-DD');
}

export const getCurrentDateMinusNDay = (N) => {
    return moment().subtract(N, 'days').format('YYYY-MM-DD');
}

export const convertKeysToLowerCase = (objArray) => {
    return objArray.map(obj => {
        const newObj = {};
        Object.keys(obj).forEach(key => {
            newObj[key.toLowerCase()] = obj[key];
        });
        return newObj;
    });
}

export const convertObjectKeysToLowerCase = (obj) => {
    const newObj = {};
    Object.keys(obj).forEach(key => {
        newObj[key.toLowerCase()] = obj[key];
    });
    return newObj;
}