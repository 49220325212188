const DashboardAnalyticSkeleton = () => {
    return (<div className="flex mb-4 lg:flex-wrap mt-4">
        <div className="border border-[#DEDEDE] bg-white p-2.5 rounded-xl pr-3 mr-3 mb-3 w-[120px]">
            <p className="h-7 w-3/4 skeleton-container bg-[#f2f2f2]">
                <span className="skeleton-content"></span>
            </p>
            <p className="h-3 w-full skeleton-container bg-[#f2f2f2] mt-3">
                <span className="skeleton-content"></span>
            </p>
        </div>
        <div className="border border-[#DEDEDE] bg-white p-2.5 rounded-xl pr-3 mr-3 mb-3 w-[120px]">
            <p className="h-7 w-3/4 skeleton-container bg-[#f2f2f2]">
                <span className="skeleton-content"></span>
            </p>
            <p className="h-3 w-full skeleton-container bg-[#f2f2f2] mt-3">
                <span className="skeleton-content"></span>
            </p>
        </div>
        <div className="border border-[#DEDEDE] bg-white p-2.5 rounded-xl pr-3 mr-3 mb-3 w-[120px]">
            <p className="h-7 w-3/4 skeleton-container bg-[#f2f2f2]">
                <span className="skeleton-content"></span>
            </p>
            <p className="h-3 w-full skeleton-container bg-[#f2f2f2] mt-3">
                <span className="skeleton-content"></span>
            </p>
        </div>
        <div className="border border-[#DEDEDE] bg-white p-2.5 rounded-xl pr-3 mr-3 mb-3 w-[120px]">
            <p className="h-7 w-3/4 skeleton-container bg-[#f2f2f2]">
                <span className="skeleton-content"></span>
            </p>
            <p className="h-3 w-full skeleton-container bg-[#f2f2f2] mt-3">
                <span className="skeleton-content"></span>
            </p>
        </div>
        <div className="border border-[#DEDEDE] bg-white p-2.5 rounded-xl pr-3 mr-3 mb-3 w-[120px] sm:hidden md:visible">
            <p className="h-7 w-3/4 skeleton-container bg-[#f2f2f2]">
                <span className="skeleton-content"></span>
            </p>
            <p className="h-3 w-full skeleton-container bg-[#f2f2f2] mt-3">
                <span className="skeleton-content"></span>
            </p>
        </div>
        <div className="border border-[#DEDEDE] bg-white p-2.5 rounded-xl pr-3 mr-3 mb-3 w-[120px] sm:hidden md:visible">
            <p className="h-7 w-3/4 skeleton-container bg-[#f2f2f2]">
                <span className="skeleton-content"></span>
            </p>
            <p className="h-3 w-full skeleton-container bg-[#f2f2f2] mt-3">
                <span className="skeleton-content"></span>
            </p>
        </div>
    </div>)
}

export default DashboardAnalyticSkeleton