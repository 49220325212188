import React from "react";
import User3FillIcon from "remixicon-react/User3FillIcon";
import SearchLineIcon from "remixicon-react/SearchLineIcon";
import SearchPopup from "../../components/SearchPopup";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRecentSearch, getUserInfo, isbrandCategory, saveRecentSearch } from "../../../core/actions/analytic.action";
import { useNavigate } from "react-router-dom";
import TrendInsights from "../../components/SearchPage/TrendInsights";
import TopActiveUser from "../../components/SearchPage/TopActiveUser";
import TopPerformingCategories from "../../components/SearchPage/TopPerformingCategories";
import { identifyString, removeSpecialCharacters } from "../../../utils/helperFunctions";
import toast from "react-hot-toast";
import { ALERT_MESSAGES, INFO_MESSAGES, L1_CATEGORY_IDS } from "../../../config/constant";
import { logout } from "../../../core/actions/auth.action";




const Searchpage = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { user } = useSelector((s) => s.authStore)
    const { recentSearches } = useSelector((s) => s.analyticStore)

    let [searchStr, setSearchStr] = useState("")
    const [showRecentsearch, setShowRecentsearch] = useState(false)
    const [showLogoutPopUp, setShowLogoutPopUp] = useState(false);

    useEffect(() => {
        dispatch(getRecentSearch(`userId=${user.id || 1}`))
    }, [])

    const handleSearhInputKeyPress = (e) => {
        if (e.key === 'Enter') {
            searchBtnClickHandler()
        }
    }

    const logoutHandler = () => {
        dispatch(logout(() => {
            toast.success(INFO_MESSAGES.LOGOUT_SUCCESSFULLY)
        }))
    }

    const searchBtnClickHandler = () => {
        const timestamp = new Date();
        const userSearchHistory = {
            timestamp: timestamp,
            searchStr: searchStr,
            userId: "",
            userName: "",
            email: "",
            categoryId: "",
            categoryName: ""
        };

        searchStr = removeSpecialCharacters(searchStr)
        const ref = identifyString(searchStr);

        let isUser = ["EMAIL", "PHONE_NUMBER", "USER_ID"].includes(ref);
        let isCategory = !isUser;

        if (isUser) {
            dispatch(getUserInfo(`searchTerm=${searchStr}`, (userInfo) => {
                if (userInfo) {
                    userSearchHistory.userId = userInfo.id;
                    userSearchHistory.userName = userInfo.username;
                    userSearchHistory.email = userInfo.email;
                }

                const userSearchPayload = {
                    userId: userInfo?.id || 1,
                    userBasedSearchHistory: [userSearchHistory],
                    categoryBasedSearchHistory: []
                };

                updateAndSaveRecentSearches(userSearchPayload);
                navigate(`/user-centered-dashboard/${userInfo?.id}`);
            }));
        } else if (isCategory) {
            dispatch(isbrandCategory(`str=${searchStr}`, (categoryInfo) => {
                if (categoryInfo.type === "category") {
                    const categoryCode = (categoryInfo?.redirectionLink?.split('/') || []).pop();
                    if (L1_CATEGORY_IDS.includes(categoryCode)) {
                        toast.error(ALERT_MESSAGES.PLEASE_SEARCH_L1_OR_L2_CATEGORY)
                        return
                    }
                    userSearchHistory.categoryId = categoryCode;
                    userSearchHistory.categoryName = categoryInfo.categoryName;

                    const categorySearchPayload = {
                        userId: user.id || 1,
                        userBasedSearchHistory: [],
                        categoryBasedSearchHistory: [userSearchHistory]
                    };

                    updateAndSaveRecentSearches(categorySearchPayload);
                    navigate(`/category-centered-dashboard/${categoryCode}`);
                } else {
                    toast.error('Invalid category');
                }
            }));
        } else {
            toast.error('Invalid search string');
        }
    };

    const updateAndSaveRecentSearches = (payload) => {
        const { userBasedSearchHistory, categoryBasedSearchHistory } = recentSearches;
        if (!userBasedSearchHistory) {
            recentSearches.userBasedSearchHistory = [];
        }
        if (!categoryBasedSearchHistory) {
            recentSearches.categoryBasedSearchHistory = [];
        }
        if (payload.userBasedSearchHistory.length > 0) {
            recentSearches.userBasedSearchHistory.push(...payload.userBasedSearchHistory);
        }

        if (payload.categoryBasedSearchHistory.length > 0) {
            recentSearches.categoryBasedSearchHistory.push(...payload.categoryBasedSearchHistory);
        }
        dispatch(saveRecentSearch(recentSearches));
    };


    return (
        <>
            <div className="bg-white pb-5">
                <div className="bg-white p-4 w-full z-10 flex justify-between ">
                    <img src="/usershher-img.svg" className="w-[186px] mr-10 cursor-pointer"></img>
                    <div className="flex items-center justify-end">
                        <div className="text-end pr-4 sm:hidden">
                            <p className='text-xs text-[#3A3A3A] leading-none'>{user?.firstName + user?.lastName || "User"} <br></br>
                                <span className='text-[10px] text-[#ADADAD] leading-4'>{user?.email || "user@gmail.com"}</span></p>
                        </div>
                        <div className="relative" onClick={() => setShowLogoutPopUp(!showLogoutPopUp)} >
                            <img src="/user-icon.png" className="h-[34px] min-w-[34px]"></img>
                            {showLogoutPopUp && (
                                <div className="w-[100px] absolute bg-white border border-[#BEBEBE] right-1 rounded-lg z-10" onClick={logoutHandler}>
                                    <p className="text-sm p-3 rounded-lg text-center bg-[#EFF6F8] font-semibold">Logout</p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="flex justify-center">
                    <div className="w-[500px] sm:px-4">
                        <div className="relative">
                            <input onKeyPress={handleSearhInputKeyPress} onClick={() => setShowRecentsearch(true)} value={searchStr} onChange={(e) => setSearchStr(e.target.value)}
                                type="text"
                                placeholder="Search for Mobile Number, Email-ID, Category Name"
                                className="relative border border-[#808080] text-sm w-full rounded-[60px] focus:outline-none focus:ring-0 focus:border-searchBorder py-2 pl-8  h-[52px]"
                            />
                            <SearchLineIcon
                                size={16}
                                className="absolute left-2.5 bottom-[18px] text-[#BFBFBF]"
                            />
                            <div onClick={searchBtnClickHandler} className="bg-[#0B66F8] rounded-full h-[46px] w-[46px] absolute flex items-center justify-center right-[3px] bottom-[3px] cursor-pointer">
                                <SearchLineIcon size={16} className="text-white" />
                            </div>
                            {showRecentsearch && Object.keys(recentSearches).length > 0 && <SearchPopup recentSearches={recentSearches} />}
                        </div>
                    </div>
                </div>
                <div className="p-4 bg-white">
                    <TrendInsights />
                    <div className="flex w-full mt-9 sm:flex-wrap">
                        <div className="w-1/2 mr-2 sm:w-full sm:mr-0">
                            <TopActiveUser />
                        </div>
                        <div className="w-1/2 ml-3 sm:w-full sm:ml-0 sm:mt-9">
                            <TopPerformingCategories />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Searchpage;
