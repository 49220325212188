import analyticActionTypes from '../actionTypes/analytic.actionType'

const {
    SET_RECENT_SEARCHES,
    SET_USER_INFO,
    SET_USER_ANALYTICS,
    SET_USER_JOURNEY,
    SET_TOP_VISITED_MSNS,
    SET_ORDER_FUNNEL,
    SET_TOP_VISITED_CATEGORIES,
    SET_TOP_ACQUISITION_CHANNEL,
    SET_TRENDING_STATE,
    SET_TOP_ACTIVE_USERS,
    SET_TOP_PERFORMING_CATEGORIES,
    SET_CATEGORY_DETAILS,
    SET_TOP_SELLING_PRODUCTS_BY_CATEGORY,
    SET_TOP_SELLING_BRANDS_BY_CATEGORY,
    SET_TOP_BUYERS_BY_CATEGORY,
    TOP_ACTIVE_USER_API_LOADER,
    TOP_ACTIVE_CATEGORY_API_LOADER,
    GET_CATEGORY_DETAILS_API_LOADER,
    USER_TOP_VISITED_CATEGORY_API_LOADER,
    TOP_SELLING_PRODUCTS_BY_CATEGORY_API_LOADER,
    TOP_BUYERS_BY_CATEGORY_API_LOADER,
    TOP_SELLING_BRANDS_BY_CATEGORY_API_LOADER,
    TOP_PERFORMING_CATEGORY_API_LOADER,
    GET_USER_INFO_API_LOADER,
    USER_ANALYTICS_API_LOADER,
    TOP_VISITED_MSN_API_LOADER,
    TRENDING_STATE_API_LOADER,
    SET_AVERAGE_VISIT_MSN_BY_CATEGORY,
    SET_CHANNEL_CATEGORY_CONVERSION_BY_CATEGORY,
    SET_TOP_USER_SEARCHES,
    

} = analyticActionTypes;

const initialState = {
    recentSearches: {},
    userInfo: {},
    getUserInfoApiLoader: false,
    userAnalytic: {},
    userAnalyticApiLoader: false,
    userJourney: [],
    topVisitedMsns: [],
    topVisitedMsnApiLoader: false,
    topActiveUsers: [],
    topActiveUserApiLoader: false,
    topPerformingCategories: [],
    topPerformingCategoryApiLoader: false,
    topActiveCategoryApiLoader: false,
    orderFunnel: {},
    topVisitedCategories: [],
    acquisitionChannel: [],
    topTrendingState: [],
    trendingStateApiLoader: false,
    categoryDetails: {},
    categoryDetailsApiLoader: false,
    userTopCategoryVisitedApiLoader: false,
    topSellingProductsByCategoryApiLoader: false,
    topSellingProducts: [],
    topSellingBrands: {},
    topBuyers: [],
    topBuyersByCategoryApiLoader: false,
    topSellingBrandsByCategoryApiLoader: false,
    averageVisitMsnByCategory: {},
    channelCategoryConversionByCategory: [],
    userTopSearchesApiLoader: false,
    userTopSearches: []
}

const analyticReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_RECENT_SEARCHES:
            return {
                ...state,
                recentSearches: action.payload
            }
        case SET_USER_INFO:
            return {
                ...state,
                userInfo: action.payload
            }
        case GET_USER_INFO_API_LOADER:
            return {
                ...state,
                getUserInfoApiLoader: action.payload
            }
        case SET_USER_ANALYTICS:
            return {
                ...state,
                userAnalytic: action.payload
            }
        case USER_ANALYTICS_API_LOADER:
            return {
                ...state,
                userAnalyticApiLoader: action.payload
            }
        case SET_USER_JOURNEY:
            return {
                ...state,
                userJourney: action.payload,
            }
        case SET_TOP_VISITED_MSNS:
            return {
                ...state,
                topVisitedMsns: action.payload
            }
        case TOP_VISITED_MSN_API_LOADER:
            return {
                ...state,
                topVisitedMsnApiLoader: action.payload
            }
        case SET_TOP_ACTIVE_USERS:
            return {
                ...state,
                topActiveUsers: action.payload
            }
        case SET_TOP_PERFORMING_CATEGORIES:
            return {
                ...state,
                topPerformingCategories: action.payload
            }
        case SET_ORDER_FUNNEL:
            return {
                ...state,
                orderFunnel: action.payload
            }
        case SET_TOP_VISITED_CATEGORIES:
            return {
                ...state,
                topVisitedCategories: action.payload
            }
        case SET_TOP_ACQUISITION_CHANNEL:
            return {
                ...state,
                acquisitionChannel: action.payload
            }
        case SET_TRENDING_STATE:
            return {
                ...state,
                topTrendingState: action.payload
            }
        case TRENDING_STATE_API_LOADER:
            return {
                ...state,
                trendingStateApiLoader: action.payload
            }
        case SET_CATEGORY_DETAILS:
            return {
                ...state,
                categoryDetails: action.payload
            }
        case SET_TOP_SELLING_PRODUCTS_BY_CATEGORY:
            return {
                ...state,
                topSellingProducts: action.payload
            }
        case SET_TOP_SELLING_BRANDS_BY_CATEGORY:
            return {
                ...state,
                topSellingBrands: action.payload
            }
        case SET_TOP_BUYERS_BY_CATEGORY:
            return {
                ...state,
                topBuyers: action.payload
            }
        case TOP_ACTIVE_USER_API_LOADER:
            return {
                ...state,
                topActiveUserApiLoader: action.payload
            }
        case TOP_ACTIVE_CATEGORY_API_LOADER:
            return {
                ...state,
                topActiveCategoryApiLoader: action.payload
            }
        case GET_CATEGORY_DETAILS_API_LOADER:
            return {
                ...state,
                categoryDetailsApiLoader: action.payload
            }
        case USER_TOP_VISITED_CATEGORY_API_LOADER:
            return {
                ...state,
                userTopCategoryVisitedApiLoader: action.payload
            }
        case TOP_SELLING_PRODUCTS_BY_CATEGORY_API_LOADER:
            return {
                ...state,
                topSellingProductsByCategoryApiLoader: action.payload
            }
        case TOP_BUYERS_BY_CATEGORY_API_LOADER:
            return {
                ...state,
                topBuyersByCategoryApiLoader: action.payload
            }
        case TOP_SELLING_BRANDS_BY_CATEGORY_API_LOADER:
            return {
                ...state,
                topSellingBrandsByCategoryApiLoader: action.payload
            }
        case TOP_PERFORMING_CATEGORY_API_LOADER:
            return {
                ...state,
                topPerformingCategoryApiLoader: action.payload
            }
        case SET_AVERAGE_VISIT_MSN_BY_CATEGORY:
            return {
                ...state,
                averageVisitMsnByCategory: action.payload
            }
        case SET_CHANNEL_CATEGORY_CONVERSION_BY_CATEGORY:
            return {
                ...state,
                channelCategoryConversionByCategory: action.payload
            }
        case SET_TOP_USER_SEARCHES:
            return {
                ...state,
                userTopSearches: action.payload
            }
        default:
            return state
    }
}


export default analyticReducer