import { useDispatch, useSelector } from 'react-redux'
import FundsFillIcon from 'remixicon-react/FundsFillIcon'
import { getAverageVisitMsnByCategory, getChannelCategoryConversionByCategory } from '../../../core/actions/analytic.action'
import { useEffect } from 'react'
import moment from 'moment'
import queryString from 'query-string'
import { capitalizeFirstLetter, getCurrentDate, getCurrentDateMinusNDay } from '../../../utils/helperFunctions'
import { DEFAULT_TIME_FRAME } from '../../../config/constant'

const CategoryInsight = ({ searchCriteria }) => {
    const dispatch = useDispatch()
    const { channelCategoryConversionByCategory, averageVisitMsnByCategory } = useSelector(s => s.analyticStore)

    useEffect(() => {
        const clonedSearchCriteria = { ...searchCriteria }
        clonedSearchCriteria.startDate = moment(clonedSearchCriteria.startDate).format("DD-MM-YYYY")
        clonedSearchCriteria.endDate = moment(clonedSearchCriteria.endDate).format("DD-MM-YYYY")
        const stringifiedSearchCriteria = queryString.stringify(clonedSearchCriteria)
        dispatch(getChannelCategoryConversionByCategory(stringifiedSearchCriteria, null))
        dispatch(getAverageVisitMsnByCategory(stringifiedSearchCriteria, null))
    }, [searchCriteria])

    return (
        <>
            <div className="mt-5 sm:mt-4">
                <h1 className="font-bold text-lg leading-6 sm:text-base">Insights</h1>
                <div className="border border-[#DEDEDE] mt-4 bg-white rounded-xl">
                    {channelCategoryConversionByCategory?.map((_d, index) =>
                        <div className="flex m-4 border-b border-[#DEDEDE] pb-4 last:border-b-0 last:pb-0">
                            <FundsFillIcon size={26} className="bg-[#0881FC] text-white w-[20px] h-5 rounded-full" />
                            <div className='text-[#3A3A3A] pl-4 w-[calc(100%-25px)]'>
                                <p className='text-sm font-semibold  leading-4'>In this category {capitalizeFirstLetter(_d?.channel)} has {_d?.conversion?.toFixed(2) || 0}% of conversion</p>
                                <p className='text-xs mt-2'>Duration: {getCurrentDateMinusNDay(searchCriteria?.lastNDays || DEFAULT_TIME_FRAME)} - {getCurrentDate()}</p>
                            </div>
                        </div>
                    )}
                    <div className="flex m-4 border-b border-[#DEDEDE] pb-4 last:border-b-0 last:pb-0">
                        <FundsFillIcon size={26} className="bg-[#0881FC] text-white w-[20px] h-5 rounded-full" />
                        <div className='text-[#3A3A3A] pl-4 w-[calc(100%-25px)]'>
                            <p className='text-sm font-semibold  leading-4'>On an average users visit {parseInt(averageVisitMsnByCategory[searchCriteria?.category] || 0)} pages before placing an order in this category</p>
                            <p className='text-xs mt-2'>Duration: {getCurrentDateMinusNDay(searchCriteria?.lastNDays || DEFAULT_TIME_FRAME)} - {getCurrentDate()}</p>
                        </div>
                    </div>
                </div>
            </div>

            {/* Insight skeleton  */}
            {/* <div className="mt-5">
                <h1 className="h-[15px] w-[60%] skeleton-container bg-[gray]">
                    <span className="skeleton-content"></span>
                </h1>
                <div className="border border-[#DEDEDE] mt-4 bg-white rounded-xl">
                <div className="flex m-4 border-b border-[#DEDEDE] pb-4 last:border-b-0 last:pb-0">
                        <div className='skeleton-container w-5 h-5 rounded-full bg-[#f2f2f2]'>
                            <span className="skeleton-content"></span>
                        </div>
                        <div className='ml-4 w-full'>
                            <div className=' skeleton-container w-full h-3 rounded-full bg-[#f2f2f2]'>
                                <span className="skeleton-content"></span>
                            </div>
                            <div className='mt-2 skeleton-container w-[60%] h-3 rounded-full bg-[#f2f2f2]'>
                                <span className="skeleton-content"></span>
                            </div>
                        </div>
                    </div>
                    <div className="flex m-4 border-b border-[#DEDEDE] pb-4 last:border-b-0 last:pb-0">
                        <div className='skeleton-container w-5 h-5 rounded-full bg-[#f2f2f2]'>
                            <span className="skeleton-content"></span>
                        </div>
                        <div className='ml-4 w-full'>
                            <div className=' skeleton-container w-full h-3 rounded-full bg-[#f2f2f2]'>
                                <span className="skeleton-content"></span>
                            </div>
                            <div className='mt-2 skeleton-container w-[60%] h-3 rounded-full bg-[#f2f2f2]'>
                                <span className="skeleton-content"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    )
}
export default CategoryInsight