import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import queryString from 'query-string'
import { getAcquisitionChannel } from '../../core/actions/analytic.action'
import { platformMapper } from '../../utils/helperFunctions'
import moment from 'moment'
import FacebookCircleFillIcon from 'remixicon-react/FacebookCircleFillIcon';
import GoogleFillIcon from 'remixicon-react/GoogleFillIcon'
import CopyrightFillIcon from 'remixicon-react/CopyrightFillIcon'
import YoutubeFillIcon from 'remixicon-react/YoutubeFillIcon'
import QuestionFillIcon from 'remixicon-react/QuestionFillIcon'

const UserPlatform = ({ searchCriteria }) => {
    const dispatch = useDispatch()
    const { acquisitionChannel } = useSelector(s => s.analyticStore)

    useEffect(() => {
        const clonedSearchCriteria = { ...searchCriteria }
        clonedSearchCriteria.startDate = moment(clonedSearchCriteria.startDate).format("DD-MM-YYYY")
        clonedSearchCriteria.endDate = moment(clonedSearchCriteria.endDate).format("DD-MM-YYYY")
        const stringifiedSearchCriteria = queryString.stringify(clonedSearchCriteria)
        dispatch(getAcquisitionChannel(stringifiedSearchCriteria, null))
    }, [searchCriteria])

    return (
        <>
            {acquisitionChannel.length > 0 && <div className='mt-5'>
                <h1 className="text-[#3A3A3A] text-lg font-bold sm:text-base">Top 5 User Acquisition Channels</h1>
                <div className="border border-[#DEDEDE] bg-white rounded-xl mt-4">
                    <div className="flex w-full border-b border-[#DEDEDE]">
                        <div className="w-[35%] px-4 py-3">
                            <p className="text-sm text-[#3A3A3A] font-semibold">Platform</p>
                        </div>
                        <div className="w-[25%] px-4 py-3">
                            <p className="text-sm text-[#3A3A3A] font-semibold">Visits</p>
                        </div>
                        <div className="w-[40%] px-4 py-3">
                            <p className="text-sm text-[#3A3A3A] font-semibold">Avg. Visit Duration</p>
                        </div>
                    </div>

                    {acquisitionChannel?.map((_d, index) =>
                        <div key={index} className="flex items-center w-full first:border-t first:border-[#DEDEDE]">
                            <div className="w-[35%] px-4 py-3">
                                <p className="text-sm text-[#3A3A3A] flex items-center">
                                    {platformMapper(_d?.channel) === 'Facebook' && <FacebookCircleFillIcon size={20} className="mr-1" />}
                                    {platformMapper(_d?.channel) === 'Google' && <GoogleFillIcon size={20} className="mr-1" />}
                                    {platformMapper(_d?.channel) === 'Criteo' && <CopyrightFillIcon size={20} className="mr-1" />}
                                    {platformMapper(_d?.channel) === 'YouTube' && <YoutubeFillIcon size={20} className="mr-1" />}
                                    {platformMapper(_d?.channel) === 'Unknown' && <QuestionFillIcon size={20} className="mr-1" />}
                                    {platformMapper(_d?.channel)}</p>
                            </div>
                            <div className="w-[25%] px-4 py-3">
                                <p className="text-sm text-[#3A3A3A]">{_d.totalVisits}</p>
                            </div>
                            <div className="w-[40%] px-4 py-3">
                                <p className="text-sm text-[#3A3A3A]">{_d.duration}</p>
                            </div>
                        </div>
                    )}
                    {/* <p className='w-full px-4 py-3 text-[#2680EA] text-sm pl-[49px]'>Show Others</p> */}
                </div>
            </div>}

            {/* skeleton */}

            {/* <div className='mt-5'>
                <h1 className="h-[15px] w-[60%] skeleton-container bg-[gray]">
                    <span className="skeleton-content"></span>
                </h1>
                <div className="border border-[#DEDEDE] bg-white rounded-xl mt-4">
                    <div className="flex w-full px-4 py-3 ">
                        <div className="w-[35%]">
                            <p className="skeleton-container h-4 bg-[#f2f2f2] w-[80%]">
                                <span className="skeleton-content"></span>
                            </p>
                        </div>
                        <div className="w-[25%]">
                            <p className="skeleton-container h-4 bg-[#f2f2f2] w-[80%]">
                                <span className="skeleton-content"></span>
                            </p>
                        </div>
                        <div className="w-[40%]">
                            <p className="skeleton-container h-4 bg-[#f2f2f2] w-[100%]">
                                <span className="skeleton-content"></span>
                            </p>
                        </div>
                    </div>

                    <div className=''>
                        <div className="flex items-center w-full px-4 py-3 ">
                            <div className="w-[35%]">
                                <p className="text-sm text-[#3A3A3A] flex items-center">
                                    <p className="skeleton-container h-3 bg-[#f2f2f2] w-[80%]">
                                        <span className="skeleton-content"></span>
                                    </p>
                                </p>
                            </div>
                            <div className="w-[25%]">
                                <p className="text-sm text-[#3A3A3A]">
                                    <p className="skeleton-container h-3 bg-[#f2f2f2] w-[80%]">
                                        <span className="skeleton-content"></span>
                                    </p>
                                </p>
                            </div>
                            <div className="w-[40%]">
                                <p className="text-sm text-[#3A3A3A]">
                                    <p className="skeleton-container h-3 bg-[#f2f2f2] w-[80%]">
                                        <span className="skeleton-content"></span>
                                    </p>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    )
}
export default UserPlatform;