import { useDispatch, useSelector } from "react-redux"
import { DEFAULT_TIME_FRAME, SORT_BY, TOP_SELLING_PRODUCT_TABLE_HEADERS } from "../../../config/constant"
import queryString from "query-string"
import { useEffect, useState } from "react"
import TopActiveCategorySkeleton from "../Skeleton/TopActiveCategorySkeleton"
import { getTopSellingProductsByCategory } from "../../../core/actions/analytic.action"
import moment from "moment"
import NoresultFound from "../NoresultFound"

const SellingProducts = ({ searchCriteria }) => {
    const dispatch = useDispatch()
    const { topSellingProducts, topSellingProductsByCategoryApiLoader } = useSelector(s => s.analyticStore)

    const [_searchCriteria, _setSearchCriteria] = useState({})
    const [sortBy, setSortBy] = useState(SORT_BY.ASC)

    useEffect(() => {
        // delete searchCriteria.lastNDays
        _setSearchCriteria({
            ...searchCriteria
            // lastNDays: DEFAULT_TIME_FRAME
        })
    }, [searchCriteria])

    useEffect(() => {
        if (_searchCriteria.category) {
            const clonedSearchCriteria = { ..._searchCriteria }
            clonedSearchCriteria.startDate = moment(clonedSearchCriteria.startDate).format("DD-MM-YYYY")
            clonedSearchCriteria.endDate = moment(clonedSearchCriteria.endDate).format("DD-MM-YYYY")
            const stringifiedSearchCriteria = queryString.stringify(clonedSearchCriteria)
            dispatch(getTopSellingProductsByCategory(stringifiedSearchCriteria, null))
        }
    }, [_searchCriteria])

    const handleSorting = (fieldName) => {
        let _sortBy = sortBy === SORT_BY.ASC ? SORT_BY.DESC : SORT_BY.ASC
        setSortBy(_sortBy)
        let clonedSearchCriteria = { ..._searchCriteria }
        clonedSearchCriteria.order = _sortBy
        clonedSearchCriteria.sortingField = fieldName
        const _stringifiedSearchCriteria = queryString.stringify(clonedSearchCriteria)
        dispatch(getTopSellingProductsByCategory(_stringifiedSearchCriteria, null))
    }

    return (
        <>
            <div className="w-full mt-5">
                <h1 className="text-[#3A3A3A] text-lg font-bold sm:text-base">Top Selling Products</h1>
                <div className="md:visible border border-[#DEDEDE] rounded-xl mt-4 max-h-[436px] sm:overflow-scroll scrollbar-hide overflow-auto">
                    <div className="flex w-full sm:sticky sm:top-0 sm:w-fit sm:z-[1] sticky top-0 border-b border-b-[#DEDEDE] rounded-tl-xl rounded-tr-xl bg-white">
                        <div className="w-2/5 text-sm font-semibold text-[#3A3A3A] px-4 py-3 sm:sticky sm:top-0 sm:w-[200px] sm:min-w-[200px] sm:bg-[#f2f2f2] sm:[&:nth-child(1)]:sticky sm:[&:nth-child(1)]:left-0 sm:[&:nth-child(1)]:bg-[#f2f2f2] sm:[&:nth-child(1)]:z-[1]">Product Details</div>
                        <div onClick={() => handleSorting("viewCount")} className="w-1/5 text-sm font-semibold text-[#3A3A3A] px-4 py-3 cursor-pointer sm:sticky sm:top-0 sm:w-[100px] sm:min-w-[100px] sm:bg-[#f2f2f2]">View Count</div>
                        <div onClick={() => handleSorting("orderCount")} className="w-1/5 text-sm font-semibold text-[#3A3A3A] px-4 py-3 cursor-pointer sm:sticky sm:top-0 sm:w-[100px] sm:min-w-[100px] sm:bg-[#f2f2f2]">Order Count</div>
                        <div className="w-1/5 text-sm font-semibold text-[#3A3A3A] px-4 py-3 cursor-pointer sm:sticky sm:top-0 sm:w-[100px] sm:min-w-[100px] sm:bg-[#f2f2f2]">Conversion Rate</div>
                    </div>
                    {topSellingProductsByCategoryApiLoader ? <TopActiveCategorySkeleton /> : topSellingProducts.length === 0 ? <NoresultFound /> :
                        <>
                            {topSellingProducts?.map((_d, index) =>
                                <div key={index} className="flex border-t border-[#DEDEDE] first:border-t-0 sm:w-fit bg-white">
                                    <div className="w-2/5 flex px-4 py-3 sm:w-[200px] sm:min-w-[200px] sm:[&:nth-child(1)]:sticky sm:[&:nth-child(1)]:left-0 sm:[&:nth-child(1)]:bg-white">
                                        <div className="w-[54px] h-[54px] min-w-[54px] border border-[#DEDEDE] rounded-[10px]">
                                            <img src={`https://cdn.moglix.com/${_d.productimage}`} className=" w-full rounded-[10px] " />
                                        </div>
                                        <div className="pl-4">
                                            <a target="_blank" href={`https://www.moglix.com/${_d.productlink}`} className="text-[#3A3A3A] text-sm sm:line-clamp-2 ">{_d?.productname}</a>
                                            {/* <p className="text-[#0881FC] text-xs ">{_d?.productName}</p> */}
                                            {/* <p className="text-[#3A3A3A] text-sm mt-1">I-Flo 0.5HP Water Pump with 1 Year Warranty, Total Head: 82 ft</p> */}
                                        </div>
                                    </div>
                                    <div className="w-1/5 text-sm text[#3A3A3A] px-4 py-3 sm:w-[100px] sm:min-w-[100px]">{_d.viewcount}</div>
                                    <div className="w-1/5 text-sm text[#3A3A3A] px-4 py-3 sm:w-[100px] sm:min-w-[100px]">{_d.ordercount}</div>
                                    <div className="w-1/5 text-sm text[#3A3A3A] px-4 py-3 sm:w-[100px] sm:min-w-[100px]">{_d.conversionrate}</div>
                                </div>
                            )}
                        </>
                    }
                </div>
            </div>
        </>
    )
}
export default SellingProducts;