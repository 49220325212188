let API_GATEWAY_BASE_URL = process.env.REACT_APP_API_GATEWAY;

export default {
    LOGIN_WITH_MICROSOFT: `${API_GATEWAY_BASE_URL}/api/development/oauth/microsoft`,
    SAVE_RECENT_SEARCH: `${API_GATEWAY_BASE_URL}/api/development/analytic/userSphere/saveRecentSearch`,
    GET_RECENT_SEARCH: `${API_GATEWAY_BASE_URL}/api/development/analytic/userSphere/getRecentSearch?`,
    GET_USER_ANALYTICS: `${API_GATEWAY_BASE_URL}/api/development/analytic/userSphere/getUserAnalytics?`,
    GET_USER_JOURNEY: `${API_GATEWAY_BASE_URL}/api/development/analytic/userSphere/getUserJourney?`,
    GET_USER_DETAILS: `${API_GATEWAY_BASE_URL}/api/development/analytic/userSphere/getUserDetails?`,
    GET_TOP_VISITED_MSNS: `${API_GATEWAY_BASE_URL}/api/development/analytic/userSphere/getTopVisitedMsns?`,
    GET_ORDER_FUNNEL: `${API_GATEWAY_BASE_URL}/api/development/analytic/userSphere/getOrderFunnel?`,
    GET_TOP_VISITED_CATEGORIES: `${API_GATEWAY_BASE_URL}/api/development/analytic/userSphere/getTopVisitedCategories?`,
    GET_USER_TOP_SEARCHES: `${API_GATEWAY_BASE_URL}/api/development/analytic/userSphere/getUserTopSearchQuery?`,
    GET_ACQUISITION_CHANNEL: `${API_GATEWAY_BASE_URL}/api/development/analytic/userSphere/getAcquisitionChannel?`,
    GET_TRENDING_STATES: `${API_GATEWAY_BASE_URL}/api/development/analytic/userSphere/getTrendingStates`,
    IS_BRAND_CATEGORY: `${API_GATEWAY_BASE_URL}/api/development/search/isBrandCategory?`,
    GET_TOP_ACTIVE_USERS: `${API_GATEWAY_BASE_URL}/api/development/analytic/userSphere/getActiveUsers?`,
    GET_TOP_PERFORMING_CATEGORIES: `${API_GATEWAY_BASE_URL}/api/development/analytic/userSphere/getActiveCategories?`,
    GET_TRENDING_STATES: `${API_GATEWAY_BASE_URL}/api/development/analytic/userSphere/getTrendingStates?`,
    IS_BRAND_CATEGORY: `${API_GATEWAY_BASE_URL}/api/development/search/isBrandCategory?`,
    GET_TOP_BUYERS_BY_CATEGORY: `${API_GATEWAY_BASE_URL}/api/development/analytic/userSphere/getTopBuyersByCategory?`,
    GET_TOP_SELLING_BRANDS_BY_CATEGORY: `${API_GATEWAY_BASE_URL}/api/development/analytic/userSphere/getTopSellingBrandsByCategory?`,
    GET_TOP_SELLING_PRODUCTS_BY_CATEGORY: `${API_GATEWAY_BASE_URL}/api/development/analytic/userSphere/getTopSellingProductsByCategoryV2?`,
    GET_CATEGORY_DETAILS: `${API_GATEWAY_BASE_URL}/api/development/analytic/userSphere/getCategoryDetails?`,
    DOWNLOAD_USER_VISITS: `${API_GATEWAY_BASE_URL}/api/development/analytic/userSphere/downloadUserVisitByCategory?`,
    AVERAGE_MSN_VISIT_BY_CATEGORY: `${API_GATEWAY_BASE_URL}/api/development/analytic/userSphere/averageVisitMsnByCategory?`,
    CHANNEL_CATEGORY_CONVERSION_BY_CATEGORY: `${API_GATEWAY_BASE_URL}/api/development/analytic/userSphere/channelCategoryConversionByCategory?`,
    DOWNLOAD_USER_VISIT_BY_CATEGORY: `${API_GATEWAY_BASE_URL}/api/development/analytic/userSphere/downloadUserVisitByCategory?`,
    FETCH_OMS_MICRO_TASK_DATA: `${API_GATEWAY_BASE_URL}/api/development/analytic/event/fetchAverage`
}