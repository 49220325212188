
export const ALERT_MESSAGES = {
    OOPS_LOGIN_FAILED: "Oops login failed.",
    OOPS_LOGOUT_FAILED: "Oops logout failed.",
    SOMETHING_WENT_WRONG: "Something went wrong.",
    USER_NOT_FOUND: "User not found.",
    PLEASE_SEARCH_L1_OR_L2_CATEGORY: "Please search l1 or l2 category",
}

export const INFO_MESSAGES = {
    LOGOUT_SUCCESSFULLY: "Logout successfully.",
    DATA_NOT_FOUND: "Data not found."
}

export const PUBLIC_ROUTES = ["oms-micro-track"]

export const SORT_BY = {
    ASC: "asc",
    DESC: "desc"
}

export const USER_TOKEN_KEY = "ONLINE_USER_EXPLORER_AUTH_TOKEN"

export const ENVIRONMENT = {
    DEVELOPMENT: "DEVELOPMENT"
}

export const DEFAULT_TIME_FRAME_OPTIONS = [3, 7, 15, 30]
export const DEFAULT_ACTIVE_USER_COUNT = [7, 15, 30]
export const DEFAULT_TIME_FRAME = 7
export const DEFAULT_DEVICE = 'web'
export const DEFAULT_COUNT = 7
export const DEFAULT_LAST_N_DAY = 7

export const DEFAULT_TOP_USERS_COUNT = 7
export const DEFAULT_TOP_USER_LAST_N_DAY_COUNT = 7
export const DEFAULT_TOP_CATEGORIES_COUNT = 7
export const DEFAULT_TOP_CATEGORIES_LAST_N_DAY_COUNT = 7

export const JOURNEY_EXPLORER_TIME_FRAME_OPTIONS = [3, 7, 15, 30]
export const JOURNEY_EXPLORER_DEFAULT_TIME_FRAME = 7

export const L1_CATEGORY_IDS = [
    "230000000",
    "214000000",
    "114000000",
    "250000000",
    "128000000",
    "211000000",
    "129000000",
    "120000000",
    "116000000",
    "216000000",
    "213000000",
    "118000000",
    "215000000",
    "112630000",
    "126000000",
    "220000000",
    "115000000",
    "212000000",
    "124000000",
    "350000000",
    "127000000",
    "121000000",
    "240000000",
    "217000000",
    "123000000",
    "218000000",
    "125000000",
    "122000000",
    "113000000",
    "117000000",
    "330000000",
    "270000000",
    "610000000",
    "111000000",
    "480000000",
    "310000000",
    "360000000",
    "119000000",
    "380000000",
    "450000000",
    "470000000",
    "219000000",
    "600000000"
]