import authActionTypes from '../actionTypes/auth.actionType'
const { SET_USER_LOADER,
    SET_USER,
    SET_USER_LOGOUT,
    SET_IS_AUTHENTICATED,
} = authActionTypes;

const initialState = {
    user: {},
    isAuthenticated: false,
    loader: false,
}

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_USER:
            return {
                ...state,
                user: action.payload
            }
        case SET_IS_AUTHENTICATED:
            return {
                ...state,
                isAuthenticated: action.payload
            }
        case SET_USER_LOADER:
            return {
                ...state,
                loader: action.payload
            }
        case SET_USER_LOGOUT:
            return {
                ...state,
                user: {},
                isAuthenticated: false,
            }
        default:
            return state
    }
}


export default authReducer