const analyticActionTypes = {
    SET_RECENT_SEARCHES: "SET_RECENT_SEARCHES",
    SET_USER_INFO: "SET_USER_INFO",
    GET_USER_INFO_API_LOADER: "GET_USER_INFO_API_LOADER",
    SET_USER_ANALYTICS: "SET_USER_ANALYTICS",
    USER_ANALYTICS_API_LOADER: "USER_ANALYTICS_API_LOADER",
    SET_USER_JOURNEY: "SET_USER_JOURNEY",
    SET_TOP_ACQUISITION_CHANNEL: "SET_TOP_ACQUISITION_CHANNEL",
    SET_TOP_VISITED_MSNS: "SET_TOP_VISITED_MSNS",
    TOP_VISITED_MSN_API_LOADER: "TOP_VISITED_MSN_API_LOADER",
    SET_ORDER_FUNNEL: "SET_ORDER_FUNNEL",
    SET_TOP_VISITED_CATEGORIES: "SET_TOP_VISITED_CATEGORIES",
    SET_TOP_USER_SEARCHES: "SET_TOP_USER_SEARCHES",
    SET_TRENDING_STATE: "SET_TRENDING_STATE",
    TRENDING_STATE_API_LOADER: "TRENDING_STATE_API_LOADER",
    SET_TOP_ACTIVE_USERS: "SET_TOP_ACTIVE_USERS",
    SET_TOP_PERFORMING_CATEGORIES: "SET_TOP_PERFORMING_CATEGORIES",
    TOP_PERFORMING_CATEGORY_API_LOADER: "TOP_PERFORMING_CATEGORY_API_LOADER",
    SET_CATEGORY_DETAILS: "SET_CATEGORY_DETAILS",
    SET_TOP_SELLING_PRODUCTS_BY_CATEGORY: "SET_TOP_SELLING_PRODUCTS_BY_CATEGORY",
    SET_TOP_SELLING_BRANDS_BY_CATEGORY: "SET_TOP_SELLING_BRANDS_BY_CATEGORY",
    SET_TOP_BUYERS_BY_CATEGORY: "SET_TOP_BUYERS_BY_CATEGORY",
    TOP_ACTIVE_USER_API_LOADER: "TOP_ACTIVE_USER_API_LOADER",
    TOP_ACTIVE_CATEGORY_API_LOADER: "TOP_ACTIVE_CATEGORY_API_LOADER",
    GET_CATEGORY_DETAILS_API_LOADER: "GET_CATEGORY_DETAILS_API_LOADER",
    USER_TOP_VISITED_CATEGORY_API_LOADER: "USER_TOP_VISITED_CATEGORY_API_LOADER",
    USER_TOP_SEARCH_LOADER: "USER_TOP_SEARCH_LOADER",
    TOP_SELLING_PRODUCTS_BY_CATEGORY_API_LOADER: "TOP_SELLING_PRODUCTS_BY_CATEGORY_API_LOADER",
    TOP_BUYERS_BY_CATEGORY_API_LOADER: "TOP_BUYERS_BY_CATEGORY_API_LOADER",
    TOP_SELLING_BRANDS_BY_CATEGORY_API_LOADER: "TOP_SELLING_BRANDS_BY_CATEGORY_API_LOADER",
    SET_AVERAGE_VISIT_MSN_BY_CATEGORY: "SET_AVERAGE_VISIT_MSN_BY_CATEGORY",
    SET_CHANNEL_CATEGORY_CONVERSION_BY_CATEGORY: "SET_CHANNEL_CATEGORY_CONVERSION_BY_CATEGORY"
}

export default analyticActionTypes