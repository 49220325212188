import React, { useEffect, useState } from "react";
import BrandCategory from "../../components/CategoryPage/BrandCategory";
import CategoryAnalytics from "../../components/CategoryPage/CategoryAnalytics";
import CategoryBlock from "../../components/CategoryPage/CategoryBlock";
import CategoryBuyers from "../../components/CategoryPage/CategoryBuyers";
import CategoryInsight from "../../components/CategoryPage/CategoryInsight";
import SellingProducts from "../../components/CategoryPage/SellingProducts";
import TrendingState from "../../components/CategoryPage/TrendingState";
import CommonHeader from "../../components/CommonHeader";
import Filter from "../../components/Filter";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getCategoryDetails } from "../../../core/actions/analytic.action";
import queryString from "query-string";
import { DEFAULT_DEVICE, DEFAULT_TIME_FRAME } from "../../../config/constant";
import moment from "moment";


const CategoryCenteredDashboard = () => {
    const params = useParams();
    const dispatch = useDispatch()

    const [searchCriteria, setSearchCriteria] = useState({
        device: DEFAULT_DEVICE,
        category: params.categoryId,
        lastNDays: DEFAULT_TIME_FRAME,
        startDate: moment().subtract(DEFAULT_TIME_FRAME, 'days').format('YYYY-MM-DD'), //TODAY - DEFAULT_TIME_FRAME
        endDate: moment().format('YYYY-MM-DD')
    })

    useEffect(() => {
        if (params.categoryId && params.categoryId !== searchCriteria.category) {
            setSearchCriteria(prevSearchCriteria => ({
                ...prevSearchCriteria,
                category: params.categoryId
            }));
        }
    }, [params, searchCriteria.category]);

    useEffect(() => {
        const clonedSearchCriteria = { ...searchCriteria }
        clonedSearchCriteria.startDate = moment(clonedSearchCriteria.startDate).format("DD-MM-YYYY")
        clonedSearchCriteria.endDate = moment(clonedSearchCriteria.endDate).format("DD-MM-YYYY")
        const stringifiedSearchCriteria = queryString.stringify(clonedSearchCriteria)
        dispatch(getCategoryDetails(stringifiedSearchCriteria))
    }, [searchCriteria]);

    return (
        <>
            <CommonHeader />
            <div className="py-6 mx-4 pt-[100px]">
                <Filter searchCriteria={searchCriteria} setSearchCriteria={setSearchCriteria} />
                <div className="flex pt-5 w-full sm:hidden">
                    <div className="left-container w-[375px] mr-5">
                        <CategoryBlock categoryId={params.categoryId} />
                        <TrendingState searchCriteria={searchCriteria} />
                        <BrandCategory searchCriteria={searchCriteria} />
                        <CategoryInsight searchCriteria={searchCriteria} />
                    </div>
                    <div className="Right-container w-[calc(100%-375px)]">
                        <CategoryAnalytics searchCriteria={searchCriteria} />
                        <CategoryBuyers searchCriteria={searchCriteria} setSearchCriteria={setSearchCriteria} />
                        <SellingProducts searchCriteria={searchCriteria} />
                    </div>
                </div>
                <div className="w-full md:hidden">
                    {/* <CategoryBlock categoryId={params.categoryId} />
                    <CategoryAnalytics searchCriteria={searchCriteria} />
                    <TrendingState searchCriteria={searchCriteria} />
                    <BrandCategory searchCriteria={searchCriteria} /> */}
                    {/* <CategoryInsight searchCriteria={searchCriteria} /> */}
                    {/* <CategoryBuyers searchCriteria={searchCriteria} /> */}
                    {/* <SellingProducts searchCriteria={searchCriteria} /> */}

                </div>
            </div>
        </>
    );
};

export default CategoryCenteredDashboard;
