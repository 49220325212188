import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import queryString from "query-string";
import { useDispatch } from "react-redux";
import apis from "../../../config/apis";
import MicrosoftFillIcon from "remixicon-react/MicrosoftFillIcon";
import { loginWithMicrosoft } from "../../../core/actions/auth.action";
import toast from "react-hot-toast";

const LoginPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (window.location.search) {
      const parsedData = queryString.parse(window.location.search);
      if (parsedData) {
        if (parsedData?.token) {
          dispatch(
            loginWithMicrosoft(parsedData?.token, () => {
              navigate("/search");
            })
          );
          return;
        }

        // if (parsedData.isAuthenticated == "false") {
        //   toast.error(parsedData.message);
        //   return;
        // }
      }
    }
  }, [navigate]);

  const loginWithMicrosoftHandler = () => {
    window.open(apis.LOGIN_WITH_MICROSOFT, "_self");
  };

  return (
    <>
      <div className="w-full  bg-[red] login-bg h-[100vh] relative">
        <div className="bg-white w-[450px] absolute left-2/4 top-2/4 -translate-x-2/4 -translate-y-2/4 px-5 py-16 rounded-xl border border-[#E6E6E6] ">
        <img src="/usershher-img.svg" className="mx-auto"></img>
          <span className="text-sm text-[#3A3A3A] text-center block mt-1.5">
            Uncover, Understand, Excel
          </span>
          <div className="w-[80%] mx-auto">
            <button
              onClick={loginWithMicrosoftHandler}
              type="button"
              className="w-full bg-[#0580FA] h-10 mt-10 rounded relative p-2.5 text-xs text-white font-semibold "
            >
              <MicrosoftFillIcon
                className="text-white absolute top-2.5"
                size={20}
              />{" "}
              Login with Microsoft
            </button>
          </div>
          <p className="text-[10px] text-[#838383] pt-1 text-center">
            This workspace allows you to sign in with your @moglix.com
          </p>
        </div>
      </div>
    </>
  );
};

export default LoginPage;
