import { useDispatch, useSelector } from "react-redux"
import { DEFAULT_TIME_FRAME, DEFAULT_TIME_FRAME_OPTIONS, SORT_BY, TOP_SEARCHES_PRODUCT_TABLE_HEADERS } from "../../../config/constant"
import queryString from "query-string"
import moment from "moment"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { getTopVisitedCategories, getUserTopSearches } from "../../../core/actions/analytic.action"
import UserCategoryVisitedSkeleton from "../Skeleton/UserCategoryVisitedSkeleton"
import NoresultFound from "../NoresultFound"

const UserTopSearches = ({ searchCriteria }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { userTopSearches, userTopSearchesApiLoader } = useSelector(s => s.analyticStore)
    const [_searchCriteria, _setSearchCriteria] = useState({})
    const [sortBy, setSortBy] = useState(SORT_BY.ASC)

    useEffect(() => {
        delete searchCriteria.lastNDays
        _setSearchCriteria({
            ...searchCriteria,
            lastNDays: DEFAULT_TIME_FRAME
        })
    }, [searchCriteria])

    useEffect(() => {
        if (_searchCriteria.userId) {
            const clonedSearchCriteria = { ..._searchCriteria }
            clonedSearchCriteria.startDate = moment(clonedSearchCriteria.startDate).format("DD-MM-YYYY")
            clonedSearchCriteria.endDate = moment(clonedSearchCriteria.endDate).format("DD-MM-YYYY")
            const stringifiedSearchCriteria = queryString.stringify(clonedSearchCriteria)
            dispatch(getUserTopSearches(stringifiedSearchCriteria, null))
        }
    }, [_searchCriteria])

    const handleSorting = (fieldName) => {
        let _sortBy = sortBy === SORT_BY.ASC ? SORT_BY.DESC : SORT_BY.ASC
        setSortBy(_sortBy)
        let clonedSearchCriteria = { ..._searchCriteria }
        clonedSearchCriteria.order = _sortBy
        clonedSearchCriteria.sortingField = fieldName
        const _stringifiedSearchCriteria = queryString.stringify(clonedSearchCriteria)
        dispatch(getUserTopSearches(_stringifiedSearchCriteria, null))
    }

    return (<>
        <div className="flex items-center justify-between mt-5">
            <h1 className="text-[#3A3A3A] text-lg font-bold sm:text-base">Top Searches</h1>
            <select onChange={(e) => _setSearchCriteria({ ...searchCriteria, lastNDays: e.target.value })} className="w-[110px] py-1.5 px-2.5 border border-[#BFBFBF] rounded-md text-xs bg-white text-[#0B66F8] font-semibold focus:outline-none focus:ring-0 cursor-pointer">
                {DEFAULT_TIME_FRAME_OPTIONS?.map((_d, index) =>
                    <option value={_d} key={index}>Last {_d} days</option>
                )}
            </select>
        </div>
        <div className="md:visible border border-[#DEDEDE] rounded-xl mt-4 max-h-[436px] sm:overflow-scroll scrollbar-hide overflow-auto">
            {/* <div className="border border-[#DEDEDE] mt-4 bg-white rounded-xl rounded-bl-none rounded-br-none"> */}
            <div className="flex w-full sm:sticky sm:top-0 sm:z-[1] sticky top-0 border-b border-b-[#DEDEDE] rounded-tl-xl rounded-tr-xl sm:bg-[#f2f2f2]">
                <div className="w-3/5 text-sm font-semibold text-[#3A3A3A] px-4 py-3 sm:sticky sm:top-0 sm:w-[200px] sm:min-w-[200px] sm:bg-[#f2f2f2] sm:[&:nth-child(1)]:sticky sm:[&:nth-child(1)]:left-0 sm:[&:nth-child(1)]:bg-[#f2f2f2] sm:[&:nth-child(1)]:z-[1]">Search Details</div>
                <div onClick={() => handleSorting("visitcount")} className="w-1/5 text-sm font-semibold text-[#3A3A3A] px-4 py-3 sm:sticky sm:top-0 sm:w-[120px] sm:min-w-[120px] sm:bg-[#f2f2f2]">Search Count</div>
            </div>
            {/* </div> */}
            {userTopSearchesApiLoader ? <UserCategoryVisitedSkeleton /> : userTopSearches.length === 0 ? <NoresultFound /> :
                <>
                    {userTopSearches?.map((_d, index) =>
                        <div className="flex border-t border-[#DEDEDE] first:border-t-0 bg-white">
                            <div className="w-3/5 flex px-4 py-3 sm:w-[200px] sm:min-w-[200px] sm:[&:nth-child(1)]:sticky sm:[&:nth-child(1)]:left-0 sm:[&:nth-child(1)]:bg-white">
                                <img src={`https://cdn.moglix.com/${_d?.categoryimg}`} className="w-[54px] h-[54px] border border-[#DEDEDE] rounded-[10px]" />
                                <div className="pl-4">
                                    <a target="_blank" href={_d.url} className="text-[#3A3A3A] text-sm cursor-pointer">{_d?.searchquery}</a><br />
                                    <a onClick={() => navigate(`/category-centered-dashboard/${_d?.categoryid}`)} class="text-[#0881FC] text-xs font-semibold cursor-pointer">{_d?.categoryname}</a>
                                </div>
                            </div>
                            <div className="w-1/5 text-sm text[#3A3A3A] px-4 py-3 sm:w-[120px] sm:min-w-[120px]">{_d?.searchcount}</div>
                        </div>
                    )}
                </>
            }
        </div>
    </>)
}

export default UserTopSearches