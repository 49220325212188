import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Route, Routes, useNavigate } from "react-router-dom";
import Login from "../pages/Login/Login.page";
import { ENVIRONMENT } from "../../config/constant";
import OmsMicroTrack from "../pages/Oms/OmsMicroTrack";

const PublicRoutes = () => {
  const authStore = useSelector((s) => s.authStore);
  const navigate = useNavigate();


  useEffect(() => {
    if (
      authStore.isAuthenticated &&
      process.env.REACT_APP_ENVIRONMENT !== ENVIRONMENT.DEVELOPMENT
    ) {
      if (window.location.pathname === "/") {
        navigate("/search");
      } else {
        navigate(window.location.pathname + window.location.search);
      }
    }
  }, [authStore.isAuthenticated]);

  return (
    <>
      <Routes>
        <Route path="/" element={<Login />}></Route>
        {/* <Route path="/oms-micro-track" element={<OmsMicroTrack />}></Route> */}
      </Routes>
    </>
  );
};

export default PublicRoutes;
